import { useSession } from "@core/hooks/useSession";
import { api } from "@core/services/nocd-api";
import { AxiosError } from "axios";
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";

import { Notification, NotificationTypes } from "../types";

export const getQueryKey = (accessToken: string): [string, string] => [
  "notifications",
  accessToken,
];

interface NotificationDTO {
  created_at: string;
  message: string;
  source_user_avatar_name: string;
  source_avatar_url: string;
  source_user_avatar_url: string;
  source_user_handle: string;
  source_user_id: number;
  thread_id: number;
  post_id: number;
  timestamp: string;
  type: string;
  unseen: boolean;
}

interface Response {
  notifications: NotificationDTO[];
}

export const useNotifications = (
  options: UseQueryOptions<undefined, AxiosError, Notification[]> = {}
): UseQueryResult<Notification[], AxiosError> => {
  const { data: session } = useSession();
  return useQuery(
    getQueryKey(session?.accessToken),
    ({ signal }): Promise<Notification[]> =>
      api
        .get<Response>("/v1/notifications", {
          headers: { Authorization: session?.accessToken },
          signal,
        })
        .then(({ data }) =>
          data.notifications
            // Only show notifications we support
            .filter(({ type }) =>
              [NotificationTypes.LIKE, NotificationTypes.REPLY].includes(
                type as NotificationTypes
              )
            )
            .map((item) => ({
              isRead: !item.unseen,
              type: item.type,
              message: item.message,
              sourceUser: {
                handle: item.source_user_handle,
                avatarUrl:
                  item.source_avatar_url ?? item.source_user_avatar_url,
                id: item.source_user_id,
              },
              age: item.timestamp,
              threadId: item.thread_id,
              postId: item.post_id,
              createdAt: new Date(item.created_at),
              key: [
                item.thread_id,
                item.post_id,
                item.created_at,
                item.source_user_id,
                item.type,
                item.message,
              ].join(),
            }))
        ),
    { enabled: !!session, ...options }
  );
};
