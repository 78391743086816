import useOnClickOutside from "@core/hooks/useOnClickOuside";
import cn from "classnames";
import { isEmpty } from "lodash/fp";
import Image from "next/image";
import NextLink from "next/link";
import { ReactNode, useCallback, useMemo, useRef } from "react";

import DropdownIcon from "./DropdownIcon";
import { DesktopLinkPositions, NavigationLink } from "./types";

interface DesktopMenuProps {
  expandedNavigationLinkId: string;
  navigationLinks: NavigationLink[];
  callToAction: ReactNode;
  closeSubMenu: () => void;
  isMobileMenuOpen: boolean;
}

export default function DesktopMenu({
  expandedNavigationLinkId,
  navigationLinks,
  callToAction,
  closeSubMenu,
  isMobileMenuOpen,
}: DesktopMenuProps): JSX.Element {
  const navRef = useRef<HTMLElement>();
  const subNavRef = useRef<HTMLElement>();

  const handleOutsideClick = useCallback(() => {
    if (isMobileMenuOpen || !expandedNavigationLinkId) {
      return;
    }

    closeSubMenu();
  }, [closeSubMenu, isMobileMenuOpen, expandedNavigationLinkId]);

  useOnClickOutside(navRef, subNavRef, handleOutsideClick);

  const leftNavigationLinks = useMemo(
    () =>
      navigationLinks.filter(
        ({ desktopPosition }) => desktopPosition === DesktopLinkPositions.LEFT
      ),
    [navigationLinks]
  );

  const rightNavigationLinks = useMemo(
    () =>
      navigationLinks.filter(
        ({ desktopPosition }) => desktopPosition === DesktopLinkPositions.RIGHT
      ),
    [navigationLinks]
  );

  const expandedNavigationLink = useMemo(
    () => navigationLinks.find((item) => item.id === expandedNavigationLinkId),
    [expandedNavigationLinkId, navigationLinks]
  );

  return (
    <>
      <header
        ref={navRef}
        className="mx-auto hidden h-[72px] max-w-[1238px] items-center justify-between space-x-6 px-6 py-4 laptop:flex"
      >
        <a
          className="-mt-2 h-[35px] w-[120px]"
          href={process.env.NEXT_PUBLIC_WEBSITE_BASE_URL}
        >
          <Image
            src="/images/nocd-logo.svg"
            alt="Go to homepage"
            width={120}
            height={35}
            priority
          />
        </a>

        <nav className="flex flex-1 justify-between">
          <ul className="flex space-x-6">
            {leftNavigationLinks
              .filter(({ hide }) => !hide)
              .map(({ Link, children, id, title }) => (
                <li key={id}>
                  <Link className="flex items-center space-x-2">
                    <span className="text-14px font-light desktop:text-16px">
                      {title}
                    </span>

                    {children ? (
                      <span className="mb-1">
                        <DropdownIcon
                          className={cn(
                            "fill-current transform transition-all duration-300",
                            expandedNavigationLinkId === id && "-rotate-180"
                          )}
                        />
                      </span>
                    ) : null}
                  </Link>
                </li>
              ))}
          </ul>

          <ul className="flex space-x-6">
            {rightNavigationLinks
              .filter(({ hide }) => !hide)
              .map(({ Link, children, id, title }) => (
                <li key={id}>
                  <Link className="flex items-center space-x-2">
                    <span className="text-14px font-light desktop:text-16px">
                      {title}
                    </span>

                    {children ? (
                      <span className="mb-1">
                        <DropdownIcon
                          className={cn(
                            "fill-current transform transition-all duration-300",
                            expandedNavigationLinkId === id && "-rotate-180"
                          )}
                        />
                      </span>
                    ) : null}
                  </Link>
                </li>
              ))}
          </ul>
        </nav>

        {callToAction}
      </header>

      <nav
        ref={subNavRef}
        className={cn(
          "duration-600 container mx-auto hidden overflow-hidden px-6 transition-all laptop:block",
          isEmpty(expandedNavigationLinkId)
            ? "pointer-events-none max-h-0 opacity-0"
            : "max-h-96 pb-8 pt-3 opacity-100"
        )}
      >
        <ul className="flex items-center justify-center space-x-24">
          {expandedNavigationLink?.children.map(
            ({ icon, title, url, isExternal, isActive }) => (
              <li key={title}>
                {isExternal ? (
                  <a
                    className="flex items-center space-x-2 transition-colors hover:text-teal-600"
                    href={url}
                  >
                    <div className="flex">{icon}</div>

                    <p className="font-bold">{title}</p>
                  </a>
                ) : (
                  <NextLink href={url}>
                    <a className="flex items-center space-x-2 transition-colors hover:text-teal-600">
                      <div className="flex">{icon}</div>

                      <p
                        className={cn(
                          "font-bold",
                          isActive && "text-teal-600 underline"
                        )}
                      >
                        {title}
                      </p>
                    </a>
                  </NextLink>
                )}
              </li>
            )
          )}
        </ul>
      </nav>
    </>
  );
}
