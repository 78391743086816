import { useSession } from "@core/hooks/useSession";
import { getInfinitePostsQueryKey } from "@features/community-v2/hooks/useInfinitePosts";
import { useUserFeedSettings } from "@features/community-v2/hooks/useUserFeedSettings";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import cn from "classnames";
import { isEmpty } from "lodash";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import {
  AnchorHTMLAttributes,
  DetailedHTMLProps,
  FC,
  forwardRef,
  Fragment,
  ReactNode,
} from "react";
import { useQueryClient } from "react-query";

import { NavigationLink } from "./types";

// https://headlessui.dev/react/menu#integrating-with-next-js
export const NextLinkWithForwardedProps: FC<
  DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>
> = forwardRef((props, ref) => {
  const { href, children, ...rest } = props;

  return (
    <Link href={href} ref={ref}>
      <a {...rest}>{children}</a>
    </Link>
  );
});

NextLinkWithForwardedProps.displayName = "NextLinkWithForwardedProps";

interface DesktopMenuProps {
  navigationLinks: NavigationLink[];
  callToAction: ReactNode;
  isTherapyMember: boolean;
  isWebView: boolean;
}

export default function DesktopMenu({
  navigationLinks,
  callToAction,
  isTherapyMember,
  isWebView,
}: DesktopMenuProps): JSX.Element {
  const router = useRouter();
  const queryClient = useQueryClient();
  const { data: session } = useSession();

  const { feedSettingsByUser } = useUserFeedSettings();
  const userFeedSettings = feedSettingsByUser?.[session?.user?.id];
  const infinitePostsQueryKey = getInfinitePostsQueryKey(
    session?.accessToken,
    userFeedSettings
  );

  return (
    <>
      <header className="mx-auto hidden h-[72px] max-w-[1072px] items-center justify-between space-x-6 px-6 py-4 tablet:flex">
        <button
          type="button"
          className="-mt-2 h-[35px] w-[120px]"
          onClick={async () => {
            // We embed this web app in our mobile apps under a "member portal"
            // tab. When the app is accessed inside of a web view, the user
            // should not be able to navigate to the community. If the user is
            // a therapy member, their "homepage" should be therapy.
            if (isWebView || isTherapyMember) {
              return router.push("/therapy", undefined, { scroll: true });
            }

            await router.push("/community/posts", undefined, { scroll: true });

            // The user is on the home page and they click the logo in the
            // navbar, refresh the main feed.
            if (router.route === "/community/posts") {
              await queryClient.invalidateQueries([infinitePostsQueryKey]);
            }

            return undefined;
          }}
        >
          <Image
            src="/images/nocd-logo.svg"
            alt="Go to homepage"
            width={120}
            height={35}
            priority
          />
        </button>
        <nav className="flex justify-between flex-1">
          <ul className="flex">
            {navigationLinks
              .filter(({ hide }) => !hide)
              .map(({ href, id, title, shouldShowAlert, subLinks }) => (
                <li key={id} className="relative">
                  {!isEmpty(subLinks) ? (
                    <Popover>
                      <Popover.Button
                        type="button"
                        className={cn(
                          "flex items-center rounded-md px-3 py-2 text-16px transition-colors hover:text-teal-600",
                          router.pathname === href &&
                            "bg-teal-100 text-teal-600"
                        )}
                      >
                        {title}

                        <ChevronDownIcon
                          className={cn("ml-1 w-5 h-5 transform duration-100")}
                        />

                        {shouldShowAlert && (
                          <span className="absolute w-2 h-2 bg-red-500 rounded-full right-2 top-2" />
                        )}
                      </Popover.Button>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel className="absolute z-10 w-screen max-w-xs mt-1 transform -translate-x-1/2 left-1/2">
                          <div className="px-3 py-4 overflow-hidden bg-white rounded-md shadow-md ring-black ring-1 ring-opacity-5">
                            {subLinks.map((item) => (
                              <Popover.Button
                                key={item.id}
                                as={NextLinkWithForwardedProps}
                                href={item.href}
                                className={cn(
                                  "hover:text-teal-600 transition flex w-full items-center space-x-3 rounded-lg px-3 py-2 text-left text-16px hover:bg-teal-100"
                                )}
                              >
                                <span className="flex items-center justify-between w-full">
                                  <span>{item.title}</span>
                                  {item.shouldShowAlert ? (
                                    <span className="block w-2 h-2 bg-red-500 rounded-full" />
                                  ) : null}
                                </span>
                              </Popover.Button>
                            ))}
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </Popover>
                  ) : (
                    <>
                      <Link href={href}>
                        <a
                          className={cn(
                            "block rounded-md px-3 py-2 text-16px transition-colors hover:text-teal-600",
                            router.pathname === href &&
                              "bg-teal-100 text-teal-600"
                          )}
                        >
                          {title}
                        </a>
                      </Link>

                      {shouldShowAlert && (
                        <span className="absolute right-1 top-2 h-2.5 w-2.5 rounded-full bg-red-500" />
                      )}
                    </>
                  )}
                </li>
              ))}
          </ul>
        </nav>

        {callToAction}
      </header>
    </>
  );
}
