import { captureException } from "@sentry/nextjs";
import type { AxiosError, AxiosRequestConfig } from "axios";
import axios from "axios";
import { identity } from "lodash/fp";
import { v4 as uuidv4 } from "uuid";

import { isApiError, isApiV3Error } from "./errors";

export type DefaultResponse = { status: "ok" };

const addLookupHeader = (config: AxiosRequestConfig) => {
  // eslint-disable-next-line no-param-reassign
  config.headers ??= {};
  // eslint-disable-next-line no-param-reassign
  config.headers["x-lookup-id"] = uuidv4();
  return config;
};

const handleError = (error: AxiosError): Promise<never> => {
  // The request was made and the server responded with a status code
  // that falls out of the range of 2xx
  if (error.response) {
    if (isApiError(error)) {
      const { data } = error.response;

      // Transform error message and name
      // eslint-disable-next-line no-param-reassign
      error.message = data.message;
      // eslint-disable-next-line no-param-reassign
      error.name = data.error_short_name;

      // Send error to Sentry
      captureException(error);
      return Promise.reject(error);
    }

    if (isApiV3Error(error)) {
      const { data } = error.response;

      // Transform error message and name
      // eslint-disable-next-line no-param-reassign
      error.message = data.message;
      // eslint-disable-next-line no-param-reassign
      error.name = data.error_type;

      // Send error to Sentry
      captureException(error);
      return Promise.reject(error);
    }
  }

  // Send error to Sentry
  captureException(error);
  return Promise.reject(error);
};

export const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_NOCD_API_BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  // 30 seconds
  timeout: 30 * 1000,
});
api.interceptors.request.use(addLookupHeader);
api.interceptors.response.use(identity, handleError);
