import { useSession } from "@core/hooks/useSession";
import IdentifyLaunchDarklyUser from "@core/utils/IdentifyLaunchDarklyUser";
import IdentifySentryUser from "@core/utils/IdentifySentryUser";
import InitializeLogRocketUser from "@core/utils/InitializeLogRocketUser";
import ThirdPartyAuthController from "@features/auth/components/ThirdPartyAuthController";
import { useLDClient } from "launchdarkly-react-client-sdk";
import React, { FC } from "react";

const SideEffects: FC = () => {
  const { data: session } = useSession();

  const isAuthenticated = !!session?.accessToken;

  // This seems redundant, but it's necessary to ensure the LD client has
  // been initialized before we try to identify the user.
  const launchDarklyClient = useLDClient();
  const isLaunchDarklyInitialized = !!launchDarklyClient;

  return (
    <>
      {isAuthenticated && <ThirdPartyAuthController key="3rd-party-auth" />}
      {isAuthenticated && <InitializeLogRocketUser key="lr-user-watcher" />}
      {isAuthenticated && <IdentifySentryUser key="sentry-user-watcher" />}
      {isAuthenticated && isLaunchDarklyInitialized && (
        <IdentifyLaunchDarklyUser key="ld-user-watcher" />
      )}
    </>
  );
};

export default SideEffects;
