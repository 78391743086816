import { useSession } from "@core/hooks/useSession";
import { getQueryKey as getUserQueryKey, User } from "@core/hooks/useUser";
import { api } from "@core/services/nocd-api";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";

import { UserProfile } from "../types";
import {
  getQueryKey as getUserProfileQueryKey,
  transformUserProfileDto,
  UserProfileDTO,
} from "./useUserProfile";

export enum ApiErrorType {
  HANDLE_EXISTS = "HandleAlreadyExists",
}

export const useMutateProfile = (
  userId?: string
): UseMutationResult<UserProfile, Error, Partial<UserProfileDTO>> => {
  const { data: session } = useSession();
  const accessToken = session?.accessToken;

  const queryClient = useQueryClient();

  return useMutation(
    async (data) => {
      const url = `/v2/community/user_profile${
        userId ? `?user_id=${userId}` : ""
      }`;

      return api
        .post<UserProfileDTO>(url, data, {
          headers: { Authorization: accessToken },
        })
        .then((response) => transformUserProfileDto(response.data));
    },
    {
      onSuccess: (profile) => {
        // See if there's any data in the query cache
        const previousUserProfile = queryClient.getQueryData<UserProfile>(
          getUserProfileQueryKey(userId, accessToken)
        );
        const previousUser = queryClient.getQueryData<User>(
          getUserQueryKey(accessToken)
        );

        if (previousUserProfile) {
          queryClient.setQueryData(
            getUserProfileQueryKey(userId, accessToken),
            profile
          );
        }

        if (previousUser) {
          queryClient.setQueryData<User>(
            getUserQueryKey(accessToken),
            (user) => ({
              ...user,
              communityHandle: profile.communityHandle,
            })
          );
        }
      },
    }
  );
};
