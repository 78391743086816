import { SVGProps } from "react";

export default function ArrowRightIcon(
  props: SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      viewBox="0 0 11 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        className="fill-current"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.3036 8.50359L0.298976 2.14366C0.106103 1.96896 0 1.73538 0 1.48633C0 1.23713 0.106103 1.00369 0.298976 0.828715L0.912759 0.271724C1.10533 0.0964715 1.36275 0 1.63706 0C1.91138 0 2.16849 0.0964715 2.36121 0.271724L10.7013 7.84377C10.8948 8.0193 11.0008 8.25385 11 8.50318C11.0008 8.75362 10.895 8.98789 10.7013 9.16355L2.36897 16.7283C2.17625 16.9035 1.91914 17 1.64467 17C1.37036 17 1.11324 16.9035 0.92037 16.7283L0.30674 16.1713C-0.0925541 15.8088 -0.0925541 15.2186 0.30674 14.8562L7.3036 8.50359Z"
      />
    </svg>
  );
}
