import ModalSheet from "@core/ui/ModalSheet";
import { useRouter } from "next/router";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  AuthenticationForm,
  AuthenticationFormVariants,
} from "../components/AuthenticationForm";

interface OpenModalArgs {
  /**
   * Redirect therapy members to the therapy dashboard after authenticating
   */
  redirectAfterAuthentication?: boolean;
  variant?: AuthenticationFormVariants;
}

interface AuthenticationModalContextProps {
  openModal: (args?: OpenModalArgs) => void;
  closeModal: () => void;
  variant?: AuthenticationFormVariants;
}

export const AuthenticationModalContext =
  createContext<AuthenticationModalContextProps>({
    openModal: () => {},
    closeModal: () => {},
  });

export const useAuthenticationModal = (): AuthenticationModalContextProps => {
  const context = useContext(AuthenticationModalContext);

  if (context === undefined) {
    throw new Error(
      `To use useAuthenticationModal, a parent component must be wrapped in a context provider.`
    );
  }

  return context;
};

interface AuthenticationModalContextProviderProps {
  children: ReactNode;
}

export const AuthenticationModalContextProvider = ({
  children,
}: AuthenticationModalContextProviderProps): JSX.Element => {
  const router = useRouter();

  const defaultOpenWithDelay = Boolean(
    Number(router?.query?.delayOpenAuthenticationModal)
  );
  const defaultOpen = Boolean(Number(router?.query?.isAuthenticationModalOpen));

  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [modalVariant, setModalVariant] =
    useState<AuthenticationFormVariants>("default");

  const alreadyOpened = useRef(defaultOpen);

  const [redirectAfterAuthentication, setRedirectAfterAuthentication] =
    useState(defaultOpen || defaultOpenWithDelay);

  const openModal = useCallback(
    ({
      redirectAfterAuthentication: newRedirectAfterAuthentication = false,
      variant = "default",
    }: OpenModalArgs = {}) => {
      setRedirectAfterAuthentication(newRedirectAfterAuthentication);
      alreadyOpened.current = true;
      setModalVariant(variant);
      return setIsOpen(true);
    },
    []
  );

  useEffect(() => {
    if (alreadyOpened.current) {
      return undefined;
    }

    if (!defaultOpenWithDelay) {
      return undefined;
    }

    const timeoutId = setTimeout(() => {
      if (alreadyOpened.current) {
        return;
      }

      openModal();
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, [defaultOpenWithDelay, openModal]);

  const closeModal = useCallback(() => setIsOpen(false), []);

  const contextValue = useMemo(
    () => ({ openModal, closeModal }),
    [openModal, closeModal]
  );

  return (
    <AuthenticationModalContext.Provider value={contextValue}>
      {children}

      <ModalSheet
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <AuthenticationForm
          redirectAfterAuthentication={redirectAfterAuthentication}
          onSuccess={() => {
            setIsOpen(false);
            return Promise.resolve();
          }}
          variant={modalVariant}
        />
      </ModalSheet>
    </AuthenticationModalContext.Provider>
  );
};
