import { RefObject, useEffect } from "react";

type AnyEvent = MouseEvent | TouchEvent;

function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  navRef: RefObject<T>,
  subNavRef: RefObject<T>,
  handler: (event: AnyEvent) => void
): void {
  useEffect(() => {
    const listener = (event: AnyEvent) => {
      const nav = navRef?.current;
      const subNav = subNavRef?.current;

      // Do nothing if clicking ref's element or descendent elements
      if (
        !nav ||
        !subNav ||
        nav.contains(event.target as Node) ||
        subNav.contains(event.target as Node)
      ) {
        return;
      }

      handler(event);
    };

    document.addEventListener(`mousedown`, listener);
    document.addEventListener(`touchstart`, listener);

    return () => {
      document.removeEventListener(`mousedown`, listener);
      document.removeEventListener(`touchstart`, listener);
    };

    // Reload only if ref or handler changes
  }, [navRef, subNavRef, handler]);
}

export default useOnClickOutside;
