import {
  transformDateTime,
  transformMoney,
  TransformMoneyOptions,
} from "@core/utils/transformers";
import { sub } from "date-fns";

import {
  Appointment,
  BalanceCellProps,
  Charge,
  ClaimStatuses,
  Coverage,
  CoveragePlan,
  Insurance,
  InsuranceCoverage,
  Invoice,
  LineApplication,
  MemberStatus,
  Overview,
  PaymentPlan,
  Plans,
  Settings,
} from "../types";

export const getTooltipText = (
  claimStatus: ClaimStatuses,
  planType: Plans,
  isLateCancellation: boolean
) => {
  if (isLateCancellation) {
    return "Late cancellation fee";
  }

  if (planType === Plans.INSURANCE) {
    switch (claimStatus) {
      case ClaimStatuses.INVOICED:
        return "We are waiting to hear back from your insurance provider.";
      case ClaimStatuses.MR_TRANSFERRED:
        return "This is the portion of your bill as determined by your insurance.";
      default:
        return null;
    }
  }
  return null;
};

export const getBalanceCellVariant = (
  amountRemaining: number,
  claimStatus: ClaimStatuses,
  planType: Plans,
  isLateCancellation: boolean
): BalanceCellProps["variant"] => {
  if (
    planType === Plans.INSURANCE &&
    claimStatus === ClaimStatuses.INVOICED &&
    !isLateCancellation
  ) {
    return "warning";
  }

  if (amountRemaining > 0) {
    return "danger";
  }

  if (amountRemaining === 0) {
    return "success";
  }

  return null;
};

interface ITransformBalanceMoney {
  options: TransformMoneyOptions;
  amountRemaining: number | null;
  totalAmount: number | null;
  claimStatus: ClaimStatuses;
  planType: Plans;
  isLateCancellation: boolean;
}

const transformBalanceMoney = ({
  amountRemaining,
  totalAmount,
  options,
  claimStatus,
  planType,
  isLateCancellation,
}: ITransformBalanceMoney): BalanceCellProps => {
  const { value, formatted } = transformMoney(amountRemaining, {
    currency: options?.currency,
    formatter: options?.formatter,
  });

  const shouldShowPendingWithTooltip =
    planType === Plans.INSURANCE &&
    claimStatus === ClaimStatuses.INVOICED &&
    totalAmount === 0 &&
    !isLateCancellation;

  return {
    value,
    formatted,
    overrideText: shouldShowPendingWithTooltip ? "Pending" : null,
    tooltipText: getTooltipText(claimStatus, planType, isLateCancellation),
    variant: getBalanceCellVariant(
      amountRemaining,
      claimStatus,
      planType,
      isLateCancellation
    ),
  };
};

export interface InsuranceDto {
  id: number;
  provider: string;
  group_number: string;
  member_id: string;
  copay: number | null;
}

export interface CoverageDto {
  start_date: string;
  end_date: string;
  id: number;
  plan_name: string;
  plan: CoveragePlan;
  is_active: boolean;

  rates?: {
    duration_30: number;
    duration_45: number;
    duration_60: number;
    duration_90: number;
    currency: string;
  };

  insurance?: {
    primary: InsuranceDto;
    secondary?: InsuranceDto;
    tertiary?: InsuranceDto;
  };
}

const transformInsuranceDto = (dto: InsuranceDto): Insurance => {
  return {
    id: dto.id,
    provider: dto.provider,
    groupNumber: dto.group_number,
    memberId: dto.member_id,
    copay: transformMoney(dto.copay, null),
  };
};

export const transformCoverageDto = (dto: CoverageDto): Coverage => {
  const coverage: Partial<Coverage> = {
    id: dto.id,
    planName: dto.plan_name,
    plan: dto.plan,
    isActive: Boolean(dto.is_active),
    startDate: dto.start_date
      ? transformDateTime(
          new Date(dto.start_date.split("Z")[0]),
          "MMM dd, yyyy"
        )
      : null,
    endDate: dto.end_date
      ? transformDateTime(
          sub(new Date(dto.end_date.split("Z")[0]), { minutes: 1 }),
          "MMM dd, yyyy"
        )
      : null,
  };

  if (coverage.plan === "cash_pay") {
    const formatter = new Intl.NumberFormat(undefined, {
      style: "currency",
      currency: dto.rates.currency || "USD",
    });

    const rates = {
      duration30: transformMoney(dto.rates?.duration_30, { formatter }),
      duration45: transformMoney(dto.rates?.duration_45, { formatter }),
      duration60: transformMoney(dto.rates?.duration_60, { formatter }),
      duration90: transformMoney(dto.rates?.duration_90, { formatter }),
      currency: dto.rates?.currency,
    };

    coverage.rates = rates;
  }

  if (coverage.plan === "insurance") {
    coverage.insurance = Object.entries(dto.insurance).reduce(
      (acc, [key, value]) => {
        acc[key] = transformInsuranceDto(value);
        return acc;
      },
      {} as InsuranceCoverage["insurance"]
    );
  }

  return coverage as Coverage;
};

export interface LineApplicationDto {
  id: number;
  // amount in cents
  amount: number;
  // type of adjustment
  adjustment_type: string;
  // raw date -- iso8601
  applied_at: string;
  // formatted data time in user's timezone
  applied_at_formatted: string;
  notes?: string;
}

export interface InvoiceDto {
  id: number;
  // raw date -- iso 8601
  invoice_date: string;
  // formatted date (in users TZ)
  invoice_date_formatted: string;
  // total amount in cents
  total_amount: number;
  // total amount already paid in cents
  amount_paid: number;
  // amount still due/the remaining balance in cents
  amount_remaining: number;
  notes?: string;
  downloadable?: boolean;
  line_applications: LineApplicationDto[];
  // if the invoice has a superbill associated with it
  billing_superbill_enabled?: boolean;
  claim_status?: string;
  plan_type?: Plans;
  is_late_cancellation?: boolean;
}

export interface AppointmentDto {
  id: number;
  invoices: InvoiceDto[];
  // raw date -- iso8601
  session_date: string;
  // formatted session date in user's TZ
  session_date_formatted: string;
  currency: string;
}

export const transformLineApplicationDto = (
  dto: LineApplicationDto,
  moneyOptions: TransformMoneyOptions = {}
): LineApplication => {
  return {
    amount: transformMoney(dto.amount, moneyOptions),
    adjustmentType: dto.adjustment_type,
    appliedAt: {
      raw: dto.applied_at,
      value: new Date(dto.applied_at),
      formatted: dto.applied_at_formatted,
    },
    notes: dto.notes,
    id: dto.id,
  };
};

export const transformInvoiceDto = (
  dto: InvoiceDto,
  moneyOptions: TransformMoneyOptions = {}
): Invoice => {
  return {
    invoiceDate: {
      raw: dto.invoice_date,
      value: new Date(dto.invoice_date),
      formatted: dto.invoice_date_formatted,
    },
    totalAmount: transformMoney(dto.total_amount, moneyOptions),
    amountPaid: transformMoney(dto.amount_paid, moneyOptions),
    amountRemaining: transformBalanceMoney({
      amountRemaining: dto.amount_remaining,
      totalAmount: dto.total_amount,
      options: moneyOptions,
      claimStatus: dto.claim_status as ClaimStatuses,
      planType: dto.plan_type,
      isLateCancellation: dto.is_late_cancellation,
    }),
    notes: dto.notes,
    // TODO: Remove this once the API is deployed
    downloadable: dto.downloadable ?? true,
    lineApplications: dto.line_applications.map((item) =>
      transformLineApplicationDto(item, moneyOptions)
    ),
    id: dto.id,
    hasSuperbill: Boolean(dto.billing_superbill_enabled),
  };
};

export const transformAppointmentDto = (dto: AppointmentDto): Appointment => {
  const formatter = new Intl.NumberFormat(undefined, {
    currency: dto.currency,
    style: "currency",
  });
  return {
    sessionDate: {
      raw: dto.session_date,
      value: new Date(dto.session_date),
      formatted: dto.session_date_formatted,
    },
    currency: dto.currency,
    invoices: dto.invoices.map((item) =>
      transformInvoiceDto(item, { formatter, currency: dto.currency })
    ),
    id: dto.id,
  };
};

export interface ChargeDto {
  amount: number;
  currency: string;

  // iso8601
  created_at: string;
  // formatted date time string
  created_at_formatted: string;

  id: number;
  notes?: string;

  receipt_url: string;
}

export const transformChargeDto = (dto: ChargeDto): Charge => {
  return {
    createdAt: {
      raw: dto.created_at,
      value: new Date(dto.created_at),
      formatted: dto.created_at_formatted,
    },
    amount: transformMoney(dto.amount, { currency: dto.currency }),
    currency: dto.currency,
    id: dto.id,
    notes: dto.notes,
    receiptUrl: dto.receipt_url,
  };
};

export interface OverviewDto {
  balance: number;
  balance_currency: string;
  has_insurance: boolean;
  paid: number;
}

export const transformOverviewDto = (dto: OverviewDto): Overview => {
  return {
    balance: transformMoney(dto.balance, { currency: dto.balance_currency }),
    hasInsurance: dto.has_insurance,
    paid: dto.paid ? transformMoney(dto.paid) : null,
  };
};

export interface SettingsDto {
  billing_superbill_enabled: boolean;
}

export const transformSettingsDto = (dto: SettingsDto): Settings => {
  return {
    superbillEnabled: Boolean(dto.billing_superbill_enabled),
  };
};

export interface PaymentPlanDto {
  amount: string;
  currency: string;
  frequency: string;
  id: number;
}

export const transformPaymentPlanDto = (dto: PaymentPlanDto): PaymentPlan => {
  const amount = Number(dto.amount);

  return {
    amount: transformMoney(amount, { currency: dto.currency }),
    frequency: dto.frequency,
    id: dto.id,
  };
};

export interface MemberStatusDto {
  is_booking_enabled: boolean;
  internal_warning: string;
  member_warning: string;
  action_type: string | null;
  formatted_due_date: string | null;
  balance_type: string | null;
}

export const transformMemberStatusDto = (
  dto: MemberStatusDto
): MemberStatus => {
  return {
    isBookingEnabled: dto.is_booking_enabled,
    internalWarning: dto.internal_warning,
    memberWarning: dto.member_warning,
    actionType: dto.action_type,
    dueDate: dto.formatted_due_date,
    balanceType: dto?.balance_type,
  };
};
