import passwordSchema from "@core/schemas/password";
import usernameSchema from "@core/schemas/username";
import { getGoogleAnalyticsClientId } from "@core/services/google-analytics";
import { recordEventInGtm } from "@core/services/gtm";
import { isApiError } from "@core/services/nocd-api";
import Button from "@core/ui/Button";
import TextField from "@core/ui/TextField";
import { yupResolver } from "@hookform/resolvers/yup";
import cn from "classnames";
import { trim } from "lodash/fp";
import { signIn } from "next-auth/react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { v4 as uuid } from "uuid";
import * as yup from "yup";

import {
  createUser,
  registerDevice,
  sendOneTimeCodeToNewUser,
} from "../services/nocd-api";

const usernameAndPasswordFormValidationSchema = yup.object({
  email: yup.string().trim().email().required("Enter a valid email address"),
  username: usernameSchema,
  password: passwordSchema,
  oneTimeCode: yup.string().optional(),
});
type RegistrationFormValues = yup.TypeOf<
  typeof usernameAndPasswordFormValidationSchema
>;

interface RegistrationFormProps {
  onSuccess: () => void;
  className?: string;
  email: string;
  onClickBack: () => void;
  source?: string;
  eventToRecord?: string;
}

export default function RegistrationForm({
  onSuccess,
  className,
  onClickBack,
  email,
  source = "member_portal",
  eventToRecord,
}: RegistrationFormProps): JSX.Element {
  const [shouldPromptUserForOneTimeCode, setShouldPromptUserForOneTimeCode] =
    useState(false);
  const [isResendingOneTimeCode, setIsResendingOneTimeCode] = useState(false);
  // We store a copy of the email in local state, so we can use it if the
  // user needs us to re-send their one time code.
  const [temporaryEmail, setTemporaryEmail] = useState<string>();
  const [oneTImeCodeMessage, setOneTimeCodeMessage] = useState<string>();

  const form = useForm<RegistrationFormValues>({
    resolver: yupResolver(usernameAndPasswordFormValidationSchema),
    defaultValues: { email },
  });

  const { register, formState } = form;
  const { isSubmitting, errors } = formState;

  const [sessionId, setSessionId] = useState<string>();
  const [deviceId, setDeviceId] = useState<string>();

  useEffect(() => {
    const getDeviceId = async () => {
      // Generate a unique ID we can use to track the user's session in
      // `nocd_v2.app_opens`.
      const newSessionId = uuid();

      const browserData: Record<string, string> = {};

      // Try to get the user's locale, so we can track it in `nocd_v2.app_opens`.
      try {
        browserData.locale = navigator.language;
      } catch {
        /* ignore */
      }

      // Try to get the user's time zone, so we can track it in
      // `nocd_v2.app_opens`.
      try {
        browserData.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      } catch {
        /* ignore */
      }

      // Get a device ID we can pass in the `X-DeviceID` header in subsequent
      // requests. We use this to track user registrations and other user events
      // in `nocd_v2.app_opens`.
      return registerDevice({
        sessionId: newSessionId,
        ...browserData,
      }).then(({ deviceID }) => {
        setDeviceId(deviceID);
        setSessionId(newSessionId);
      });
    };

    getDeviceId() as unknown as void;
  }, []);

  const onSubmit = form.handleSubmit(
    async ({
      email: formEmail,
      username,
      password,
      oneTimeCode,
    }: RegistrationFormValues) => {
      const sanitizedEmail = trim(formEmail);

      try {
        const { needsEmailVerification, emailVerificationMessage } =
          await createUser({
            email: sanitizedEmail,
            username,
            password,
            oneTimeCode,
            sessionId,
            deviceId,
            source,
          }).then(
            ({ needs_email_verification, email_verification_message }) => ({
              needsEmailVerification: needs_email_verification,
              emailVerificationMessage: email_verification_message,
            })
          );

        if (needsEmailVerification) {
          setTemporaryEmail(sanitizedEmail);
          setOneTimeCodeMessage(emailVerificationMessage);
          return setShouldPromptUserForOneTimeCode(true);
        }

        if (eventToRecord) {
          try {
            recordEventInGtm(eventToRecord);
          } catch (gtmError) {
            console.error(
              "Error recording registration in GTM ",
              gtmError instanceof Error ? gtmError.message : ""
            );
          }
        }

        return await signIn("credentials", {
          redirect: false,
          email: sanitizedEmail,
          password,
          sessionId,
          deviceId,
          gaClientId: getGoogleAnalyticsClientId(),
        }).then(onSuccess);
      } catch (error) {
        let errorMessage: string;

        if (isApiError(error)) {
          errorMessage = error?.response?.data?.message;
        } else if (error instanceof Error) {
          errorMessage = error?.message;
        } else {
          errorMessage = "An unknown error occurred";
        }

        return toast.error(errorMessage);
      }
    }
  );

  return (
    <form onSubmit={onSubmit} className={cn("mx-auto font-poppins", className)}>
      <h2 className="mb-2 text-center text-24px font-bold tablet:text-32px text-indigo-600">
        Create a NOCD account
      </h2>

      <p className="mb-12 text-center text-16px text-gray-600 tablet:text-20px">
        Get effective, convenient, affordable OCD treatment online &amp; receive
        24/7 support.
      </p>

      <div className="mb-6 space-y-8">
        <TextField
          classes={{
            root: "text-16px laptop:text-20px",
          }}
          placeholder="Email"
          id="email"
          autoCapitalize="off"
          name="email"
          disabled
          type="text"
          hideLabel
          label="Email"
          autoComplete="email"
          inputMode="email"
          errorMessage={errors.email?.message}
          {...register("email")}
        />

        <TextField
          classes={{
            root: "text-16px laptop:text-20px",
          }}
          hideLabel
          placeholder="Password"
          id="password"
          name="password"
          type="password"
          label="Password"
          autoComplete="new-password"
          errorMessage={errors.password?.message}
          {...register("password")}
        />

        <TextField
          classes={{
            root: "text-16px laptop:text-20px",
          }}
          hideLabel
          placeholder="Username"
          id="username"
          name="username"
          type="text"
          autoCapitalize="off"
          label="Username"
          autoComplete="username"
          errorMessage={errors.username?.message}
          {...register("username")}
        />

        {shouldPromptUserForOneTimeCode && (
          <>
            <TextField
              classes={{
                root: "text-16px laptop:text-20px",
              }}
              hideLabel
              label="Verification code"
              id="oneTimeCode"
              placeholder="Verification code"
              name="oneTimeCode"
              type="text"
              helperText={oneTImeCodeMessage}
              autoComplete="one-time-code"
              errorMessage={errors.oneTimeCode?.message}
              {...register("oneTimeCode")}
            />

            <Button
              type="button"
              variant="text"
              disabled={isResendingOneTimeCode}
              onClick={() => {
                setIsResendingOneTimeCode(true);

                const promise = sendOneTimeCodeToNewUser(temporaryEmail).then(
                  () => setIsResendingOneTimeCode(false)
                );

                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                toast.promise(promise, {
                  success: "Code resent",
                  loading: "Resending code...",
                  error: (error: Error) => error.message,
                });

                return promise;
              }}
            >
              Resend email
            </Button>
          </>
        )}
      </div>

      <div className="mb-12 text-14px text-gray-800 text-opacity-60">
        By signing up, you agree to NOCD&apos;s{" "}
        <a
          className="text-blue-300"
          href={`${process.env.NEXT_PUBLIC_WEBSITE_BASE_URL}/terms`}
        >
          Terms &amp; Conditions
        </a>{" "}
        and{" "}
        <a
          className="text-blue-300"
          href={`${process.env.NEXT_PUBLIC_WEBSITE_BASE_URL}/privacy-policy`}
        >
          Privacy Policy
        </a>
        .
      </div>

      <div className="grid gap-4">
        <Button
          type="submit"
          disabled={isSubmitting}
          loading={isSubmitting}
          className="w-full text-20px"
        >
          Sign up
        </Button>

        <Button
          variant="outlined"
          type="button"
          onClick={onClickBack}
          className="w-full text-20px"
        >
          Back
        </Button>
      </div>
    </form>
  );
}
