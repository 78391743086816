import Button from "@core/ui/Button";
import Loader from "@core/ui/Loader";
import TextField from "@core/ui/TextField";
import { yupResolver } from "@hookform/resolvers/yup";
import cn from "classnames";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import * as yup from "yup";

import {
  sendOneTimeCodeToExistingUser,
  testOneTimeCodeForExistingUser,
} from "../services/nocd-api";

interface OneTimeCodeFormProps {
  onSuccess: (oneTimeCode: string) => void;
  email: string;
  title: string;
  description: string;
  className?: string;
}

const schema = yup.object({
  oneTimeCode: yup.string().required("Enter your verification code"),
});
type FormValues = yup.TypeOf<typeof schema>;

export default function OneTimeCodeForm({
  onSuccess,
  email,
  title,
  description,
  className,
}: OneTimeCodeFormProps): JSX.Element {
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [isResendingCode, setIsResendingCode] = useState(false);

  const form = useForm<FormValues>({
    resolver: yupResolver(schema),
  });

  const { register, formState } = form;
  const { isSubmitting, errors } = formState;

  const onSubmit = form.handleSubmit(async ({ oneTimeCode }: FormValues) => {
    return testOneTimeCodeForExistingUser(email, oneTimeCode)
      .then(({ status }) => {
        if (status === "fail") {
          throw new Error(`Invalid one time code`);
        }
      })
      .then(() => onSuccess(oneTimeCode))
      .catch(() =>
        form.setError("oneTimeCode", {
          message: "Invalid one time code.",
        })
      );
  });

  useEffect(() => {
    setIsSendingCode(true);

    sendOneTimeCodeToExistingUser(email)
      .catch((error: Error) =>
        toast.error(error?.message ?? "An unknown error occurred")
      )
      .finally(() => setIsSendingCode(false));
  }, [email]);

  if (isSendingCode) {
    return (
      <div className="flex items-center justify-center py-16">
        <Loader className="text-32px text-teal-600" />
      </div>
    );
  }

  return (
    <form onSubmit={onSubmit} className={cn("mx-auto font-poppins", className)}>
      <h2 className="mb-2 text-center text-24px font-bold text-gray-900 tablet:text-32px text-indigo-600">
        {title}
      </h2>

      <p className="mb-12 text-center text-16px text-gray-600 tablet:text-20px">
        {description}
      </p>

      <div className="mb-12 space-y-12">
        <TextField
          classes={{
            root: "text-16px laptop:text-20px",
          }}
          placeholder="Verification code"
          label="Verification code"
          id="oneTimeCode"
          name="oneTimeCode"
          type="text"
          autoComplete="one-time-code"
          errorMessage={errors.oneTimeCode?.message}
          hideLabel
          {...register("oneTimeCode")}
        />
      </div>

      <div className="flex flex-col items-center">
        <Button
          disabled={isSubmitting}
          type="submit"
          loading={isSubmitting}
          className="mb-6 w-full text-20px tablet:w-auto"
        >
          Next
        </Button>

        <div className="text-center text-gray-700">
          Didn&apos;t receive a code?{" "}
          <Button
            variant="text"
            disabled={isResendingCode}
            onClick={() => {
              setIsResendingCode(true);

              const promise = sendOneTimeCodeToExistingUser(email).then(() =>
                setIsResendingCode(false)
              );

              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              toast.promise(promise, {
                success: "Code resent",
                loading: "Resending code...",
                error: (error: Error) => error.message,
              });

              return promise;
            }}
          >
            Click here to resend one
          </Button>
        </div>
      </div>
    </form>
  );
}
