import Container from "@core/layout/Container";
import ErrorScreen from "@core/layout/ErrorScreen";
import {
  useClearNotifications,
  useNotifications,
} from "@core/services/nocd-api/notifications";
import Button from "@core/ui/Button";
import Loader from "@core/ui/Loader";
import CogIcon from "@heroicons/react/solid/CogIcon";
import Image from "next/image";
import Link from "next/link";
import React, { FC } from "react";

import Notification from "../components/Notification";

export const NotificationsScreen: FC = () => {
  const { data, isLoading, error } = useNotifications();
  const unreadNotifications = data?.filter(({ isRead }) => !isRead);
  const readNotifications = data?.filter(({ isRead }) => isRead);

  const { mutate: clearNotifications } = useClearNotifications();

  if (error) {
    return <ErrorScreen error={error} />;
  }

  if (isLoading) {
    return (
      <div className="flex items-center justify-center py-12">
        <Loader />
      </div>
    );
  }

  return (
    <Container className="py-6 laptop:py-9">
      <div className="-mx-4 bg-white py-6 px-4 tablet:mx-0 tablet:rounded-lg tablet:p-0 tablet:shadow">
        <div className="flex flex-1 justify-between">
          <div className="flex flex-1 justify-between space-x-6 tablet:my-12 tablet:justify-start tablet:px-6">
            <h1 className="text-16px font-bold leading-none text-gray-800 tablet:text-20px laptop:text-24px">
              Notifications
            </h1>

            <Link href="/my/settings/notifications" passHref>
              <Button variant="text" as="a" className="leading-none">
                <CogIcon className="h-4 w-4 text-gray-800 tablet:h-5 tablet:w-5 laptop:h-6 laptop:w-6" />
              </Button>
            </Link>
          </div>

          <span className="hidden tablet:inline-block">
            <Image
              src="/images/notifications/corner-sun.svg"
              height={92}
              width={191}
              alt=""
              quality={100}
            />
          </span>
        </div>

        <div className="mt-6 tablet:mt-0">
          {Array.isArray(data) && data.length ? (
            <>
              {unreadNotifications?.length ? (
                <>
                  <div className="flex justify-between tablet:px-6">
                    <p className="font-bold text-gray-600">New</p>

                    <Button
                      className="text-14px tablet:text-16px"
                      onClick={() => clearNotifications()}
                      variant="text"
                    >
                      Mark all as read
                    </Button>
                  </div>

                  <div className="py-4">
                    {unreadNotifications?.map((item) => (
                      <Notification
                        notification={item}
                        key={item.key}
                        className="bg-white transition-colors duration-200 ease-in-out hover:bg-gray-100 hover:bg-opacity-50 tablet:px-6"
                      />
                    ))}
                  </div>
                </>
              ) : null}

              {readNotifications?.length ? (
                <>
                  <div className="tablet:px-6">
                    <p className="font-bold text-gray-600">Previous</p>
                  </div>

                  <div className="py-4">
                    {readNotifications?.map((item) => (
                      <Notification
                        notification={item}
                        key={item.key}
                        className="bg-white transition-colors duration-200 ease-in-out hover:bg-gray-100 hover:bg-opacity-50 tablet:px-6"
                      />
                    ))}
                  </div>
                </>
              ) : null}
            </>
          ) : (
            <div className="py-16">
              <span
                aria-label="Owl"
                className="block text-center text-[60px]"
                role="img"
              >
                🦉
              </span>
              <h2 className="text-center text-20px font-bold tablet:text-24px">
                You&apos;re all caught up!
                <br />
                This has been a real hoot.
              </h2>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};
