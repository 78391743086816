import { getGoogleAnalyticsClientId } from "@core/services/google-analytics";
import Button from "@core/ui/Button";
import TextField from "@core/ui/TextField";
import { yupResolver } from "@hookform/resolvers/yup";
import cn from "classnames";
import { trim } from "lodash/fp";
import { signIn } from "next-auth/react";
import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

interface LoginFormProps {
  onClickForgotPassword: () => void;
  onClickBack: () => void;
  onSuccess: () => unknown | Promise<unknown>;
  className?: string;
  email: string;
}

const schema = yup.object({
  email: yup
    .string()
    .trim()
    .email("Enter a valid email address")
    .required("Enter your email address"),
  password: yup.string().required("Enter your password"),
});
type FormValues = yup.TypeOf<typeof schema>;

export default function LoginForm({
  onClickForgotPassword,
  onSuccess,
  onClickBack,
  className,
  email,
}: LoginFormProps): JSX.Element {
  const form = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      email,
    },
  });

  const { register, formState } = form;
  const { errors, isSubmitting } = formState;

  const onSubmit = form.handleSubmit(
    async ({ email: formEmail, password }: FormValues) => {
      const sanitizedEmail = trim(formEmail);

      const browserData: Record<string, string> = {};

      // Try to get the user's locale, so we can track it in `nocd_v2.app_opens`.
      try {
        browserData.locale = navigator.language;
      } catch {
        /* ignore */
      }

      // Try to get the user's time zone, so we can track it in
      // `nocd_v2.app_opens`.
      try {
        browserData.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      } catch {
        /* ignore */
      }

      const result = await signIn("credentials", {
        redirect: false,
        email: sanitizedEmail,
        password,
        gaClientId: getGoogleAnalyticsClientId(),
        ...browserData,
      });

      if (result.ok) {
        return onSuccess();
      }

      return form.setError("email", {
        message: "Sign in failed. Check your email and password.",
      });
    }
  );

  return (
    <form
      onSubmit={onSubmit}
      className={cn("mx-auto w-full font-poppins", className)}
    >
      <h2 className="mb-2 text-center text-24px font-bold tablet:text-32px text-indigo-600">
        Log in to NOCD
      </h2>

      <p className="mb-12 text-center text-16px text-gray-600 tablet:text-20px">
        Enter your password below.
      </p>

      <div className="mb-12 space-y-8">
        <TextField
          hideLabel
          classes={{
            root: "text-16px laptop:text-20px",
          }}
          disabled
          id="email"
          name="email"
          type="email"
          label="Email"
          autoCorrect="off"
          autoComplete="email"
          autoCapitalize="off"
          inputMode="email"
          errorMessage={errors.email?.message}
          {...register("email")}
        />

        <TextField
          hideLabel
          classes={{
            root: "text-16px laptop:text-20px",
          }}
          id="password"
          name="password"
          type="password"
          label="Password"
          autoComplete="current-password"
          errorMessage={errors.password?.message}
          helperText={
            <Button variant="text" onClick={onClickForgotPassword}>
              Forgot password?
            </Button>
          }
          {...register("password")}
        />
      </div>

      <div className="mb-12 text-14px text-gray-800 text-opacity-60">
        By logging in, you agree to NOCD&apos;s{" "}
        <a
          className="text-blue-300"
          href={`${process.env.NEXT_PUBLIC_WEBSITE_BASE_URL}/terms`}
        >
          Terms &amp; Conditions
        </a>{" "}
        and{" "}
        <a
          className="text-blue-300"
          href={`${process.env.NEXT_PUBLIC_WEBSITE_BASE_URL}/privacy-policy`}
        >
          Privacy Policy
        </a>
        .
      </div>

      <div className="grid gap-4">
        <Button
          type="submit"
          disabled={isSubmitting}
          loading={isSubmitting}
          className="w-full text-20px"
        >
          Log in
        </Button>

        <Button
          variant="outlined"
          type="button"
          onClick={onClickBack}
          className="w-full text-20px"
        >
          Back
        </Button>
      </div>
    </form>
  );
}
