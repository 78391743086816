import { SVGProps } from "react";

export default function DropdownIcon(
  props: SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.51056 8.69817C5.21746 8.98844 4.74476 8.98738 4.45273 8.69534C3.43237 7.67499 1.28772 5.53033 1.28772 5.53033C0.994973 5.23759 0.994973 4.76241 1.28772 4.46967C1.58046 4.17693 2.05563 4.17693 2.34838 4.46967L5.00003 7.12132L7.65168 4.46967C7.94442 4.17693 8.4196 4.17693 8.71234 4.46967C9.00508 4.76241 9.00508 5.23759 8.71234 5.53033C8.71234 5.53033 6.54081 7.67923 5.51056 8.69817Z"
      />
    </svg>
  );
}
