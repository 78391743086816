import { useSession } from "@core/hooks/useSession";
import Modal, { useModal } from "@core/ui/Modal";
import React, { FC, useCallback, useEffect } from "react";
import toast from "react-hot-toast";

import Form from "./Form";

interface PersistedState {
  userId: number;
  completedAt: Date;
}

const ThirdPartyAuthController: FC = () => {
  const { data: session } = useSession();

  const isNewUser = session?.isNewUser;
  const userId = session?.user?.id;

  const storageKey = `oidc-account-setup-complete`;

  const modal = useModal<HTMLDivElement>();
  const { handleOpen, handleClose } = modal;

  const onSuccess = useCallback(() => {
    toast.success("Your account has been setup!");

    handleClose();

    localStorage.setItem(
      storageKey,
      JSON.stringify({
        userId,
        completedAt: new Date(),
      })
    );
  }, [userId, handleClose, storageKey]);

  useEffect(() => {
    if (isNewUser) {
      const rawValue = localStorage.getItem(storageKey);

      if (rawValue) {
        const value: PersistedState = JSON.parse(rawValue) as PersistedState;
        if (value.userId === userId) {
          return;
        }
      }

      handleOpen();
    }
  }, [handleOpen, isNewUser, storageKey, userId]);

  if (isNewUser !== true) {
    return null;
  }

  return (
    <Modal
      onClose={modal.handleClose}
      isOpen={modal.isOpen}
      initialFocus={modal.focusRef}
    >
      <div ref={modal.focusRef}>
        <h2 className="mb-2 text-center text-24px font-bold text-gray-900 tablet:text-32px">
          Get started with NOCD
        </h2>

        <p className="mb-12 text-center text-16px text-gray-600 tablet:text-20px">
          Set up your account
        </p>

        <Form onSuccess={onSuccess} />
      </div>
    </Modal>
  );
};

export default ThirdPartyAuthController;
