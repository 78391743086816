import { create } from "zustand";
import { persist } from "zustand/middleware";

import { FeedContentFilters } from "../types";

export interface UserFeedSettings {
  filters: FeedContentFilters[];
}

const DEFAULT_USER_FEED_SETTINGS: UserFeedSettings = {
  filters: [],
};

interface Store {
  feedSettingsByUser: {
    [key: string]: UserFeedSettings;
  };
  updateFeedSettingsByUserId: (
    settings: UserFeedSettings,
    userId: string | number
  ) => void;
  toggleMemberPostVisibilityByUserId: (userId: string | number) => void;
}

export const useUserFeedSettings = create<Store>()(
  persist(
    (set) => ({
      feedSettingsByUser: {},
      updateFeedSettingsByUserId: (
        settings: UserFeedSettings,
        userId: string | number
      ) =>
        set((state) => ({
          feedSettingsByUser: {
            ...state.feedSettingsByUser,
            [userId]: settings,
          },
        })),
      toggleMemberPostVisibilityByUserId: (userId: string | number) =>
        set((state) => {
          const userSettings =
            state?.feedSettingsByUser?.[userId] ?? DEFAULT_USER_FEED_SETTINGS;
          const userFilters = userSettings.filters;

          return {
            feedSettingsByUser: {
              ...state.feedSettingsByUser,
              [userId]: {
                ...userSettings,
                filters: userFilters.includes(FeedContentFilters.NOCD)
                  ? userFilters.filter(
                      (filter) => filter !== FeedContentFilters.NOCD
                    )
                  : userFilters.concat(FeedContentFilters.NOCD),
              },
            },
          };
        }),
    }),
    { name: "feed-settings-by-user-id" }
  )
);
