import { Session } from "@core/types";
import axios from "axios";
import { isEmpty } from "lodash/fp";
import { useRouter } from "next/router";
import { useQuery, UseQueryResult } from "react-query";

export const getQueryKey = (): string => "session";

const fetchSession = async (signal?: AbortSignal): Promise<Session | null> => {
  const response = await axios.get<Session>("/api/auth/session", { signal });
  const { data: sessionData, status } = response;

  if (status >= 400 || isEmpty(sessionData)) {
    return null;
  }

  return sessionData;
};

export function useSession<TData = Session>({
  redirectIfNotLoggedIn,
  redirectToAfterAuthentication,
  select,
}: {
  /**
   * When this is true, the user will be redirected to `/login` if they are not
   * logged in.
   */
  redirectIfNotLoggedIn?: boolean;
  /**
   * This should be used in conjunction with `redirectIfNotLoggedIn`. When the
   * user is redirect to `/login`, this value will be encoded in the URL
   * as a `redirectTo` query param.
   */
  redirectToAfterAuthentication?: string;

  select?: (data: Session) => TData;
} = {}): UseQueryResult<TData, Error> {
  const router = useRouter();
  const { push, query } = router;

  return useQuery(getQueryKey(), ({ signal }) => fetchSession(signal), {
    onSettled: (data, _error) => {
      const isLoggedIn = Boolean(data);
      const isOnLoginPage = router.route === "/login";

      if (!isOnLoginPage && !isLoggedIn && redirectIfNotLoggedIn) {
        return push({
          pathname: "/login",
          query: {
            ...query,
            redirectTo: redirectToAfterAuthentication ?? undefined,
          },
        });
      }

      // This is purely to shut ESLint up.
      return undefined;
    },
    notifyOnChangeProps: ["data", "error"],
    select,
  });
}

const selector = (session: Session) => session?.accessToken;

export const useAccessToken = () => {
  return useSession<string>({ select: selector });
};
