import { noop } from "lodash/fp";
import { useRef } from "react";

interface AndroidWebView {
  WebNavigationNew?: NativeChannel;
}

interface AppleWebView {
  webkit: { messageHandlers: { iosListener: NativeChannel } };
}

interface NativeChannel {
  postMessage: (message: string) => void;
}

// typeguards for determining if running in native webview
const isAppleWebView = (context: unknown): context is AppleWebView => {
  return !!(context as AppleWebView)?.webkit?.messageHandlers?.iosListener;
};

const isAndroidWebViewNew = (context: unknown): context is AndroidWebView =>
  (context as AndroidWebView)?.WebNavigationNew !== undefined;

const isAndroidWebView = (context: unknown): context is AndroidWebView =>
  isAndroidWebViewNew(context);

const mockWebView: NativeChannel = {
  postMessage: noop,
};

const getNativeChannel = (): NativeChannel => {
  // iOS
  if (typeof window !== "undefined" && isAppleWebView(window)) {
    return window.webkit.messageHandlers.iosListener;
  }

  // Android
  if (typeof window !== "undefined" && isAndroidWebView(window)) {
    if (window.WebNavigationNew !== undefined) {
      return window.WebNavigationNew;
    }
  }

  // return a mock webview to make our life easier
  return mockWebView;
};

interface UseNativeContext {
  channel: NativeChannel;
  emit: (event: string) => void;
  back: () => void;
  close: () => void;
  editAvatar: () => void;
  editAboutBackground: () => void;
  refresh: () => void;
  bookACall: (scheduler_url: string) => void;
  onboardingComplete: () => void;
  logOut: () => void;
  open: (url: string) => void;
  openNative: ({ type, data }: { type: string; data: string }) => void;
}

const useNativeContext = (): UseNativeContext => {
  const channel = useRef(getNativeChannel());

  const methods = useRef({
    emit: (event: string) => {
      channel.current.postMessage(event);
    },
    back: () => {
      channel.current.postMessage(JSON.stringify({ event: "BACK" }));
    },
    close: () => {
      channel.current.postMessage(JSON.stringify({ event: "CLOSE" }));
    },
    logOut: () => {
      channel.current.postMessage(JSON.stringify({ event: "LOGOUT" }));
    },
    refresh: () => {
      channel.current.postMessage(JSON.stringify({ event: "REFRESH" }));
    },
    editAvatar: () => {
      channel.current.postMessage(JSON.stringify({ event: "EDIT_AVATAR" }));
    },
    editAboutBackground: () => {
      channel.current.postMessage(JSON.stringify({ event: "EDIT_BACKGROUND" }));
    },
    bookACall: (scheduler_url: string) => {
      channel.current.postMessage(
        JSON.stringify({ event: "BOOK_A_CALL", scheduler_url })
      );
    },
    onboardingComplete: () => {
      channel.current.postMessage(
        JSON.stringify({ event: "ONBOARDING_COMPLETE" })
      );
    },
    open: (url: string) => {
      channel.current.postMessage(JSON.stringify({ event: "OPEN", url }));
    },
    openNative: ({ type, data }: { type: string; data: string }) => {
      channel.current.postMessage(
        JSON.stringify({ event: "OPEN_NATIVE", type, data })
      );
    },
  });

  return {
    channel: channel.current,
    emit: methods.current.emit,
    close: methods.current.close,
    refresh: methods.current.refresh,
    back: methods.current.back,
    logOut: methods.current.logOut,
    editAvatar: methods.current.editAvatar,
    editAboutBackground: methods.current.editAboutBackground,
    bookACall: methods.current.bookACall,
    onboardingComplete: methods.current.onboardingComplete,
    open: methods.current.open,
    openNative: methods.current.openNative,
  };
};

export default useNativeContext;
