import { DateTime, Money } from "@core/types";
import format from "date-fns/fp/format";

// transform an ISO date string in UTC or numeric timestamp into a DateTime
//
// formatString: https://date-fns.org/v2.22.1/docs/format
export const transformDateTime = (
  raw: string | number | Date,
  formatString = "P p"
): DateTime => {
  const value = new Date(raw);
  const formatted = format(formatString, value);

  return {
    raw,
    value,
    formatted,
  };
};

export interface TransformMoneyOptions {
  currency?: string;
  formatter?: Intl.NumberFormat;
}

// transform and format the amount in **cents** to the Money type. Optionally accepts both
// the currency and Intl.NumberFormat instance, if none are provided a new formatter
// instance will be created and the currency will default to USD.
export const transformMoney = (
  amount: number | null,
  options: TransformMoneyOptions = {}
): Money | null => {
  if (typeof amount === "number") {
    const formatMoney =
      options?.formatter ||
      new Intl.NumberFormat(undefined, {
        style: "currency",
        currency: options?.currency || "USD",
      });

    return {
      value: amount,
      formatted: formatMoney.format(amount / 100),
    };
  }

  return null;
};
