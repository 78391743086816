import {
  Notification as INotification,
  NotificationTypes,
  useClearNotifications,
  useMutateNotifications,
  useNotifications,
} from "@core/services/nocd-api/notifications";
import Button from "@core/ui/Button";
import Loader from "@core/ui/Loader";
import { NotificationIcon } from "@features/user-notifications/components/Notification";
import { Menu } from "@headlessui/react";
import BellIcon from "@heroicons/react/outline/BellIcon";
import CogIcon from "@heroicons/react/solid/CogIcon";
import cn from "classnames";
import { isEmpty } from "lodash/fp";
import Router from "next/router";
import { FC } from "react";

interface NotificationProps {
  notification: INotification;
  isActive: boolean;
}

const Notification: FC<NotificationProps> = ({ notification, isActive }) => {
  const { mutateAsync } = useMutateNotifications();

  return (
    <button
      type="button"
      onClick={() =>
        mutateAsync({
          postId: notification.postId,
          threadId: notification.threadId,
        }).then(() => Router.push(`/community/posts/${notification.postId}`))
      }
      className={cn(
        "flex w-full items-center justify-between space-x-4 border-t border-gray-100 p-4 text-left",
        isActive && "bg-teal-600 bg-opacity-10"
      )}
    >
      <NotificationIcon type={notification.type as NotificationTypes} />

      <div
        className={cn(
          "text-14px",
          !notification.isRead ? "text-gray-900" : "text-gray-600"
        )}
      >
        {notification.message}
      </div>

      <span className="flex-shrink-0 text-xs text-gray-600">
        {notification.age}
      </span>
    </button>
  );
};

const PreviewNotificationsMenu: FC = () => {
  const { data: notifications, isLoading: isNotificationsLoading } =
    useNotifications({
      select: (items: INotification[]) => items,
      refetchInterval: 30000,
      refetchIntervalInBackground: false,
    });

  const unreadNotifications = notifications?.filter(({ isRead }) => !isRead);
  const readNotifications = notifications?.filter(({ isRead }) => isRead);

  const hasUnreadNotifications = (unreadNotifications ?? []).length > 0;

  const { mutate: clearNotifications } = useClearNotifications();

  return (
    <Menu as="div" className="phone:relative">
      <Menu.Button className="relative flex items-center rounded-full p-2 transition-colors duration-200 ease-in-out hover:bg-gray-100">
        <div className="relative">
          <BellIcon className="h-6 w-6 text-gray-600 hover:text-gray-800" />
          {hasUnreadNotifications && (
            <>
              <div className="absolute top-0 right-0 flex h-3 w-3 items-center justify-center">
                <span className="absolute h-3 w-3 animate-ping rounded-full bg-red-600 opacity-50" />
                <span className="absolute h-3 w-3 rounded-full border-2 border-white bg-red-600" />
              </div>

              <div
                style={{
                  width: "calc(100% + 1rem)",
                  height: "calc(100% + 1rem)",
                }}
                className="absolute -top-2 -right-2 rounded-full border-2 border-teal-600"
              />
            </>
          )}
        </div>
      </Menu.Button>

      <Menu.Items className="absolute top-16 right-[50%] translate-x-[50%] phone:right-0 phone:translate-x-0 tablet:top-14">
        <div className="border-b-g h-0 w-0 border-b-8" />
        <div className="w-80 overflow-hidden rounded-md bg-white shadow-md ring-1 ring-black ring-opacity-5">
          <div className="flex items-center justify-between px-4 pt-6 pb-4 text-sm">
            <div className="text-20px font-bold text-gray-800">
              Notifications
            </div>

            <Menu.Item>
              {({ active }) => (
                <button
                  type="button"
                  onClick={() => Router.push("/my/settings/notifications")}
                  className={cn(
                    "-mr-2 rounded-full p-2",
                    active && "bg-teal-600 bg-opacity-10"
                  )}
                >
                  <CogIcon
                    className="h-4 w-4 text-gray-600"
                    aria-label="Manage notification settings"
                  />
                </button>
              )}
            </Menu.Item>
          </div>

          <div className="max-h-80 overflow-y-auto tablet:max-h-105">
            <div>
              {isNotificationsLoading && (
                <div className="my-5 flex justify-center">
                  <Loader />
                </div>
              )}

              {unreadNotifications?.length ? (
                <>
                  <div className="flex justify-between px-4 pb-4">
                    <p className="font-bold text-gray-600">New</p>

                    <Button
                      variant="text"
                      className="text-14px"
                      onClick={() => clearNotifications()}
                    >
                      Mark all as read
                    </Button>
                  </div>
                  {Array.isArray(unreadNotifications) &&
                    unreadNotifications.map((notification) => (
                      <Menu.Item key={notification.key}>
                        {({ active }) => (
                          <Notification
                            isActive={active}
                            notification={notification}
                          />
                        )}
                      </Menu.Item>
                    ))}
                </>
              ) : null}

              {readNotifications?.length ? (
                <>
                  <div className="p-4">
                    <p className="font-bold text-gray-600">Previous</p>
                  </div>
                  {Array.isArray(readNotifications) &&
                    readNotifications.map((notification) => (
                      <Menu.Item key={notification.key}>
                        {({ active }) => (
                          <Notification
                            isActive={active}
                            notification={notification}
                          />
                        )}
                      </Menu.Item>
                    ))}
                </>
              ) : null}

              {!isNotificationsLoading && isEmpty(notifications) && (
                <div className="py-8 px-6 text-center text-sm">
                  You are all caught up! No unread notifications.
                </div>
              )}
            </div>
          </div>

          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={() => Router.push("/my/notifications")}
                className={cn(
                  "block w-full border-t border-gray-100 p-4 text-center text-sm font-bold text-teal-600",
                  {
                    "bg-teal-600 bg-opacity-10": active,
                  }
                )}
              >
                View all
              </button>
            )}
          </Menu.Item>
        </div>
      </Menu.Items>
    </Menu>
  );
};

export default PreviewNotificationsMenu;
