declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

export const recordEventInGtm = (event: string): void => {
  if (!event) {
    return;
  }

  if (window && window.dataLayer) {
    window.dataLayer.push({
      event,
    });
  }
};

export const recordUnauthCommunityRegistrationClick = (): void => {
  recordEventInGtm("cuapcc");
};
