import { Dialog, Transition } from "@headlessui/react";
import XIcon from "@heroicons/react/outline/XIcon";
import cn from "classnames";
import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  Dispatch,
  FC,
  Fragment,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

interface UseModalHook<
  T extends HTMLElement = HTMLElement,
  Target = undefined
> {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  handleOpen: () => void;
  handleClose: () => void;
  focusRef: MutableRefObject<T | null>;

  // When the `Target` is provided, this method will create specialized
  // handleOpen callbacks. When one of the specialized (bounded) callbacks
  // are invoked, it will also set an internal state exposed as `open`. This
  // value can then be used by the caller, to conditionally display the
  // modals contents. The primary use case for this is to create a single
  // modal in a component which conditionally displays its contents. The
  // alternative to this would be creating multiple modals in the calling
  // component. In caller components, creating and handling multiple modals
  // inndividually can create require a lot of unnecessary code.
  boundedOpener: (target: Target) => () => void;
  open: null | Target;
}

interface UseModalOptions {
  beforeClose?: () => boolean | undefined;
  initiallyOpened?: boolean;
}

type Cache<E extends string> = Partial<Record<E, () => void>>;

// hook to make working with a modal easier, optionally accepts beforeClose
// option that runs some action before allowing the modal to be closed. This
// could be used in instances where the modal contains a form and you don't want
// the user to lose unsaved changes
export function useModal<
  T extends HTMLElement = HTMLElement,
  Target extends string = undefined
>({ beforeClose, initiallyOpened }: UseModalOptions = {}): UseModalHook<
  T,
  Target
> {
  const [isOpen, setIsOpen] = useState(!!initiallyOpened);
  const [open, setOpen] = useState<Target | null>(null);

  const focusRef = useRef<T>(null);
  const cache = useRef<{ isInitialized: boolean; fns: Cache<Target> }>({
    isInitialized: false,
    fns: {},
  });

  const handleOpen = useCallback(
    (ev?: MouseEvent) => {
      if (ev) {
        ev.preventDefault();
      }
      setIsOpen(true);
    },
    [setIsOpen]
  );

  const handleClose = useCallback(() => {
    let shouldClose = true;

    if (beforeClose) {
      const shouldPreventDefault = beforeClose();
      if (shouldPreventDefault === false) {
        shouldClose = false;
      }
    }

    if (shouldClose) {
      setIsOpen(false);
    }
  }, [setIsOpen, beforeClose]);

  // Create a "bounded" callback and store it in the cache. This is meant to
  // emulate useCallback for dynamic callbacks. This is works by storing a
  // tagged function (i.e. a function associated with a specific string). When
  // a function is requested the first time, it is created and cached.
  // Subsequent requests will return the cached/memoized function.
  const boundedOpener = useCallback(
    (bound: Target) => {
      if (!cache.current.fns[bound]) {
        cache.current.fns[bound] = () => {
          setOpen(bound);
          setIsOpen(true);
        };
      }
      return cache.current.fns[bound];
    },
    [setOpen, setIsOpen]
  );

  // reset the cached/memoized bounded openers
  // when the boundedOpener function changes, we need to reset all the cached
  // callbacks. this really is only for handling edge cases/theoretical
  // scenarios.
  useEffect(() => {
    if (cache.current.isInitialized) {
      cache.current.fns = {};
    }
    cache.current.isInitialized = true;
  }, [boundedOpener]);

  return {
    isOpen,
    setIsOpen,
    handleOpen,
    handleClose,
    focusRef,
    boundedOpener,
    open,
  };
}

type ButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & { position?: "left" | "right" | "custom"; iconClassName?: string };

interface Props {
  isOpen?: boolean;
  onClose: () => void;
  initialFocus?: MutableRefObject<HTMLElement | null>;
  backgroundColor?:
    | "white"
    | "gray"
    | "lightBlue"
    | "mediumBlue"
    | "black"
    | "none";
  size?:
    | "extraSmall"
    | "small"
    | "medium"
    | "large"
    | "extraLarge"
    | "full"
    | null;
  className?: string;
  disablePadding?: boolean;
  disableShadow?: boolean;
  disableTransition?: boolean;
  growOnMobile?: boolean;
  overlayColor?:
    | "white"
    | "gray"
    | "lightBlue"
    | "mediumBlue"
    | "black"
    | "none";
  overlayOpacity?: 0 | 60 | 100;
  z?: 20 | 40 | 60;
}

type ModalFC = FC<Props> & {
  Title: typeof Dialog.Title;
  CloseButton: FC<ButtonProps>;
};

const Modal: ModalFC = ({
  isOpen,
  onClose,
  initialFocus,
  children,
  className,
  size = "small",
  backgroundColor = "white",
  overlayColor = "black",
  overlayOpacity = 60,
  disablePadding = false,
  disableShadow = false,
  disableTransition = false,
  growOnMobile = true,
  z = 20,
}) => {
  return (
    <Transition appear={!disableTransition} show={!!isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={cn(`fixed inset-0 overflow-y-auto`, {
          "z-20": z === 20,
          "z-40": z === 40,
          "z-60": z === 60,
        })}
        onClose={onClose}
        initialFocus={initialFocus}
      >
        <div
          className={cn(
            "flex min-h-screen justify-center",
            growOnMobile
              ? "tablet:items-center tablet:px-4"
              : "items-center px-4"
          )}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className={cn(`fixed inset-0 min-h-screen min-w-full`, {
                "bg-white": overlayColor === "white",
                "bg-gray-100": overlayColor === "gray",
                "bg-[#EEF8FF]": overlayColor === "lightBlue",
                "bg-[#E4F6F6]": overlayColor === "mediumBlue",
                "bg-black": overlayColor === "black",
                "bg-opacity-0": overlayOpacity === 0,
                "bg-opacity-60": overlayOpacity === 60,
                "bg-opacity-100": overlayOpacity === 100,
              })}
            />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={cn(
                "flex-1 transform transition-all tablet:my-8",
                growOnMobile ? "tablet:rounded-2xl" : "rounded-2xl",
                className,
                {
                  "tablet:shadow-xl": disableShadow === false,
                  "bg-white": backgroundColor === "white",
                  "bg-gray-100": backgroundColor === "gray",
                  "bg-[#EEF8FF]": backgroundColor === "lightBlue",
                  "bg-[#E4F6F6]": backgroundColor === "mediumBlue",
                  "bg-black": backgroundColor === "black",
                  "tablet:max-w-[440px] max-w-full":
                    size === "extraSmall" && growOnMobile,
                  "tablet:max-w-[640px] max-w-full":
                    size === "small" && growOnMobile,
                  "tablet:max-w-[840px] max-w-full":
                    size === "medium" && growOnMobile,
                  "tablet:max-w-[960px] max-w-full":
                    size === "large" && growOnMobile,
                  "tablet:max-w-[1200px] max-w-full":
                    size === "extraLarge" && growOnMobile,
                  "tablet:max-w-screen max-w-full":
                    size === "full" && growOnMobile,
                  "max-w-[440px]": size === "extraSmall" && !growOnMobile,
                  "max-w-[640px]": size === "small" && !growOnMobile,
                  "max-w-[840px]": size === "medium" && !growOnMobile,
                  "max-w-[960px]": size === "large" && !growOnMobile,
                  "max-w-[1200px]": size === "extraLarge" && !growOnMobile,
                  "max-w-screen": size === "full" && !growOnMobile,

                  "py-16 px-6 tablet:px-8":
                    size !== "extraSmall" && !disablePadding,
                  "p-6 py-8": size === "extraSmall" && !disablePadding,
                }
              )}
            >
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

const CloseButton: FC<ButtonProps> = ({
  className,
  position = "right",
  iconClassName = "w-6",
  ...props
}) => {
  return (
    <button
      type="button"
      {...props}
      className={cn(
        "absolute",
        {
          "top-5": position !== "custom",
          "right-5": position === "right",
          "left-5": position === "left",
        },
        className
      )}
    >
      <XIcon className={iconClassName} />
    </button>
  );
};

Modal.Title = Dialog.Title;
Modal.CloseButton = CloseButton;

export default Modal;
