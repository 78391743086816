import { useSession } from "@core/hooks/useSession";
import { setUser } from "@sentry/nextjs";
import { isNil } from "lodash/fp";
import { useEffect } from "react";

const IdentifySentryUser = () => {
  const { data } = useSession();
  const user = data?.user;
  const email = user?.email;
  const userId = user?.id;

  useEffect(() => {
    if (email && !isNil(userId) && process.env.NODE_ENV === "production") {
      console.log("Identifying Sentry user...");
      void setUser({
        email,
        id: String(userId),
      });
    }
  }, [email, userId]);

  return null;
};

export default IdentifySentryUser;
