import { api } from "@core/services/nocd-api";
import * as yup from "yup";

interface ValidateUsernameResponse {
  is_valid: boolean;
  isValid: boolean;
  message: string;
}

export const validateUsername = (
  username: string
): Promise<ValidateUsernameResponse> =>
  api
    .post<ValidateUsernameResponse>("/v1/validate_handle", {
      userHandle: username,
    })
    .then(({ data }) => data);

const username = yup
  .string()
  .required("Enter a username")
  .test("is-username-taken", "Username already taken", async (value) => {
    if (value) {
      const { isValid } = await validateUsername(value);

      return isValid;
    }

    return false;
  });

export default username;
