import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";

type IsAuthenticatingContextProps = [
  boolean,
  Dispatch<SetStateAction<boolean>>
];

const IsAuthenticating = createContext<IsAuthenticatingContextProps>([
  false,
  () => {},
]);

export const useIsAuthenticating = (): IsAuthenticatingContextProps =>
  useContext(IsAuthenticating);

interface IsAuthenticatingProps {
  children: ReactNode;
}

export function IsAuthenticatingProvider({
  children,
}: IsAuthenticatingProps): JSX.Element {
  const state = useState(false);

  return (
    <IsAuthenticating.Provider value={state}>
      {children}
    </IsAuthenticating.Provider>
  );
}
