import { useIsAuthenticating } from "@core/hooks/useIsAuthenticating";
import { useSession } from "@core/hooks/useSession";
import Container from "@core/layout/Container";
import type { Page } from "@core/types";
import Loader from "@core/ui/Loader";
import { AuthenticationModalContextProvider } from "@features/auth/hooks/useAuthenticationModal";
import cn from "classnames";
import { isEmpty } from "lodash";
import Script from "next/script";
import React, { FC } from "react";

import AuthenticatedNavBar from "./AuthenticatedNavBar";
import SideEffects from "./SideEffects";
import UnauthenticatedNavBar from "./UnauthenticatedNavBar";

interface Props {
  className?: string;
  hideNavBar?: Page["hideNavBar"];
  hideNavBarOnMobile?: Page["hideNavBarOnMobile"];
  backgroundColor?: Page["backgroundColor"];
  shouldShowConsentDialog?: Page["shouldShowConsentDialog"];
  hideNavbarShadowOnMobile?: Page["hideNavbarShadowOnMobile"];
}

const buildBgColorCss = (
  color: Page["backgroundColor"]
): string | Record<string, boolean> => {
  if (isEmpty(color)) {
    return "bg-gray-100";
  }

  if (typeof color === "string") {
    return {
      "bg-gray-100": color === "gray",
      "bg-white": color === "white",
      "bg-purple-100": color === "purple",
      "bg-indigo-100": color === "indigo",
      "bg-[#F8FBFE]": color === "gray-v2",
    };
  }

  const root = {
    "bg-gray-100": color.root === "gray",
    "bg-white": color.root === "white",
    "bg-purple-100": color.root === "purple",
    "bg-indigo-100": color.root === "indigo",
  };

  const phone = {
    "phone:bg-gray-100": color.phone === "gray",
    "phone:bg-white": color.phone === "white",
    "phone:bg-purple-100": color.phone === "purple",
    "phone:bg-indigo-100": color.phone === "indigo",
  };

  const tablet = {
    "tablet:bg-gray-100": color.tablet === "gray",
    "tablet:bg-white": color.tablet === "white",
    "tablet:bg-purple-100": color.tablet === "purple",
    "tablet:bg-indigo-100": color.tablet === "indigo",
  };

  const laptop = {
    "laptop:bg-gray-100": color.laptop === "gray",
    "laptop:bg-white": color.laptop === "white",
    "laptop:bg-purple-100": color.laptop === "purple",
    "laptop:bg-indigo-100": color.laptop === "indigo",
  };

  const desktop = {
    "desktop:bg-gray-100": color.desktop === "gray",
    "desktop:bg-white": color.desktop === "white",
    "desktop:bg-purple-100": color.desktop === "purple",
    "desktop:bg-indigo-100": color.desktop === "indigo",
  };

  return {
    ...root,
    ...phone,
    ...tablet,
    ...laptop,
    ...desktop,
  };
};

const Layout: FC<Props> = ({
  className,
  children,
  hideNavBar = false,
  hideNavBarOnMobile = false,
  backgroundColor = "gray",
  shouldShowConsentDialog = false,
  hideNavbarShadowOnMobile = false,
}) => {
  const { data: session } = useSession();
  const isAuthenticated = !!session?.accessToken;
  const [isAuthenticating] = useIsAuthenticating();

  // Allow hiding the navbar at the page level _or_ the session level
  const shouldHideNavBar = hideNavBar || session?.hideNavBar;

  return (
    <AuthenticationModalContextProvider>
      <div
        className={cn(
          "flex min-h-screen flex-col text-gray-800",
          className,
          buildBgColorCss(backgroundColor)
        )}
      >
        <div
          className={cn({
            "hidden phone:block": hideNavBarOnMobile,
          })}
        >
          {isAuthenticated && !shouldHideNavBar && (
            <AuthenticatedNavBar
              hideShadowOnMobile={hideNavbarShadowOnMobile}
            />
          )}

          {!isAuthenticated && !shouldHideNavBar && (
            <UnauthenticatedNavBar
              hideShadowOnMobile={hideNavbarShadowOnMobile}
            />
          )}
        </div>

        {!isAuthenticated &&
          shouldShowConsentDialog &&
          process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_CONTAINER_ID && (
            <Script
              id="gtm"
              strategy="afterInteractive"
              dangerouslySetInnerHTML={{
                __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_CONTAINER_ID}');`,
              }}
            />
          )}

        <div
          className={cn(
            "flex flex-1",
            isAuthenticated &&
              !shouldHideNavBar &&
              !hideNavBarOnMobile &&
              "mt-[58px] tablet:mt-[72px]",
            !isAuthenticated &&
              !shouldHideNavBar &&
              !hideNavBarOnMobile &&
              "mt-[58px] laptop:mt-[72px]",
            {
              "phone:mt-[58px] tablet:mt-[72px]":
                isAuthenticated && hideNavBarOnMobile,
              "phone:mt-[58px] laptop:mt-[72px]":
                !isAuthenticated && hideNavBarOnMobile,
            }
          )}
        >
          {isAuthenticating ? (
            <Container className="flex items-center justify-center py-32">
              <Loader className="text-32px text-teal-600" />
            </Container>
          ) : (
            children
          )}
        </div>
      </div>
      <SideEffects />
    </AuthenticationModalContextProvider>
  );
};

export default Layout;
