export enum UserType {
  CLINICIAN = "therapist",
  MEMBER = "default",
  ADVOCATE = "advocate",
}

export interface ClinicianProfile {
  name: string;
  displayName: string;
  communityHandle: string;
  about: string | null;
  avatarUrl: string;
  canEdit: boolean;
  education: string | null;
  states: string[];
  ocdExperience: string | null;
  headline: string | null;
  userId: string;
  userType: UserType.CLINICIAN;
  video: string | null;
  cta: {
    text: string;
    url: string;
  };
}

export interface MemberProfile {
  avatarUrl: string;
  communityHandle: string;
  displayName: string;
  userType: UserType.MEMBER | UserType.ADVOCATE;
  headline: string;
  shouldShowHeadline: boolean;
  about: string | null;
  shouldShowAbout: boolean;
  bioImageUrl: string | null;
  subtypes: Array<Subtype>;
  selectedSubtypes: Array<Subtype>;
  shouldShowSubtypes: boolean;
  interests: Array<Interest>;
  selectedInterests: Array<Interest>;
  shouldShowInterests: boolean;
  questions: Array<Question>;
  selectedQuestions: Array<Question>;
  shouldShowQuestions: boolean;
  canEdit: boolean;
  cta: {
    text: string;
    url: string;
  };
  userId: string;
}

export type UserProfile = ClinicianProfile | MemberProfile;

export interface Topic {
  title: string;
  topics: Array<{
    description: string;
    id: string;
    imageUrl: string;
    title: string;
  }>;
}

export interface Question {
  question: string;
  id: number;
  isSelected?: boolean;
  answer: string | null;
}

export interface Interest {
  id: number;
  interest: string;
  isSelected: boolean;
}

export interface Subtype {
  id: string;
  description: string;
  imageUrl: string;
  title: string;
  selected: boolean;
  isSelected: boolean;
}

export interface FormValues {
  show_caption_on_profile: boolean;
  show_about_on_profile: boolean;
  show_subtypes_on_profile: boolean;
  show_interests_on_profile: boolean;
  show_questions_on_profile: boolean;

  community_handle: string;
  caption: string;
  about: string;

  interests: {
    [key: string]: boolean;
  };
  subtypes: {
    [key: string]: boolean;
  };

  avatarId: number | null;
}
