import cn from "classnames";
import { useRouter } from "next/router";
import { signIn } from "next-auth/react";
import React, {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  FC,
  ReactElement,
  useCallback,
} from "react";

const GoogleLogo: FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width={18}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.64 9.205c0-.639-.057-1.252-.164-1.841H9v3.481h4.844a4.14 4.14 0 01-1.796 2.716v2.258h2.908c1.702-1.566 2.684-3.874 2.684-6.614z"
        fill="#4285F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 18c2.43 0 4.467-.806 5.956-2.18l-2.908-2.259c-.806.54-1.837.86-3.048.86-2.344 0-4.328-1.584-5.036-3.711H.957v2.332A8.997 8.997 0 009 18z"
        fill="#34A853"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.964 10.71A5.41 5.41 0 013.682 9c0-.593.102-1.17.282-1.71V4.958H.957A8.996 8.996 0 000 9c0 1.452.348 2.827.957 4.042l3.007-2.332z"
        fill="#FBBC05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 3.58c1.321 0 2.508.454 3.44 1.345l2.582-2.58C13.463.891 11.426 0 9 0A8.997 8.997 0 00.957 4.958L3.964 7.29C4.672 5.163 6.656 3.58 9 3.58z"
        fill="#EA4335"
      />
    </svg>
  );
};

type Props = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const Button: FC<Props> = ({ className, ...props }) => {
  return (
    <button
      className={cn(
        className,
        "w-full justify-center outline outline-gray-200 flex items-center space-x-4 rounded bg-white py-3 px-4 font-medium text-black text-opacity-50"
      )}
      type="button"
      {...props}
    >
      <GoogleLogo /> <span>Sign in with Google</span>
    </button>
  );
};

const Bound: FC<Omit<Props, "onClick">> = (props) => {
  const { query } = useRouter();
  const { redirectTo } = query;
  const normalizedRedirectTo = Array.isArray(redirectTo)
    ? redirectTo[0]
    : redirectTo;
  const nextUrl =
    typeof normalizedRedirectTo === "string" &&
    normalizedRedirectTo.startsWith("/")
      ? normalizedRedirectTo
      : undefined;

  const handleClick = useCallback(() => {
    return signIn("google", { callbackUrl: nextUrl });
  }, [nextUrl]);

  return <Button onClick={handleClick} {...props} />;
};

interface GoogleSignInComponent {
  (props: Props): ReactElement;
  Bound: (props: Omit<Props, "onClick">) => ReactElement;
}

const GoogleSignInButton: GoogleSignInComponent =
  Button as GoogleSignInComponent;
GoogleSignInButton.Bound = Bound;

export default GoogleSignInButton;
