import type { AxiosError } from "axios";

export enum APIErrorType {
  HANDLE_EXISTS = "HandleAlreadyExists",
  INVALID_ACCESS_TOKEN = "InvalidAccessToken",
  INVALID_PERMISSIONS = "InvalidPermissions",
  INVALID_OTP_TOKEN = "InvalidOTPToken",
  INVALID_INPUT_MESSAGE = "InvalidInputMessage",
  POST_DOESNT_EXIST = "PostDoesntExist",
  UNKNOWN = "Unknown",
}

export enum APIErrorTypeV3 {
  VALIDATION = "validation",
  UNAUTHORIZED = "unauthorized",
  FORBIDDEN = "forbidden",
  BAD_REQUEST = "bad_request",
}

// Type guard to narrow the unknown error to an Axios error
export const isAxiosError = (error: unknown): error is AxiosError => {
  return (error as AxiosError).isAxiosError === true;
};

type APIErrorResponse = AxiosError<{
  error_short_name: APIErrorType | string;
  message: string;
}>;

export const isApiError = (error: unknown): error is APIErrorResponse => {
  if (isAxiosError(error)) {
    const tmp = error as APIErrorResponse;
    const data = tmp.response?.data;
    return (
      data &&
      typeof data.error_short_name === "string" &&
      typeof data.message === "string"
    );
  }
  return false;
};

type ApiV3ErrorResponse = AxiosError<{
  message: string;
  error_type: APIErrorTypeV3;
  code: number;
  errors?: Array<{
    message: string;
    path: string;
    error_type: string;
    context?: unknown;
  }>;
}>;

export const isApiV3Error = (error: unknown): error is ApiV3ErrorResponse => {
  if (isAxiosError(error)) {
    const tmp = error as ApiV3ErrorResponse;
    const data = tmp.response?.data;
    return (
      data &&
      typeof data.error_type === "string" &&
      typeof data.message === "string"
    );
  }
  return false;
};
