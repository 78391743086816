import * as yup from "yup";

const schema = yup
  .string()
  .required("Enter a password")
  .min(8, "Must be at least 8 characters")
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9~!@#])(?=.{8,})/,
    "At least 8 characters, a number (123) or a symbol (~!@#), and both lower and uppercase letters (Ab)"
  );

export default schema;
