import { useSession } from "@core/hooks/useSession";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useEffect } from "react";

const IdentifyLaunchDarklyUser = () => {
  const launchDarklyClient = useLDClient();

  const { data: session } = useSession();
  const { user } = session ?? {};
  const { id: userId, email, avatar, name } = user;

  useEffect(() => {
    if (email && userId) {
      console.log("Identifying LaunchDarkly user...");
      void launchDarklyClient.identify({
        email,
        key: `${userId}`,
        avatar,
        anonymous: false,
      });
    }
  }, [userId, avatar, email, name, launchDarklyClient]);

  return null;
};

export default IdentifyLaunchDarklyUser;
