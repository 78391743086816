export enum AppointmentTypes {
  FIRST_SESSION = "First Session",
  SECOND_SESSION = "Second Session",
  THERAPY_SESSION = "Therapy Session",
  REASSESSMENT = "Reassessment",
  // Deprecated appointment types
  FIRST_THERAPY_SESSION = "First Therapy Session",
  SECOND_THERAPY_SESSION = "Second Therapy Session",
  DIAGNOSTIC_ASSESSMENT = "*OCD DIAGNOSTIC ASSESSMENT",
  THERAPY_CHECK_IN = "Therapy Check In",
  LOC_THERAPY_CHECK_IN = "LOC THERAPY CHECK-IN",
  MID_THERAPY_SESSION = "Mid Therapy Session",
  SUPPORT_CHECK_IN = "Support Check In",
  FORMS_PLACEHOLDER = "Forms Placeholder",
  SIX_MONTH_SUPPORT_CHECK_IN = "6 Month Support Check In",
}

export enum AppointmentStatuses {
  SCHEDULED = "scheduled",
  COMPLETED = "completed",
  CANCELLED = "cancelled",
}

export enum MemberProtocolTypes {
  ADULT = "Adult",
  ADOLESCENT = "Adolescent",
  CHILD = "Child",
}

export interface Member {
  userId: number;
  guid: string;
  email?: string;
  timeZone: string;
  firstName: string;
  lastName: string;
  preferredName: string;
  protocolType: MemberProtocolTypes;
  insuranceProvider: string;
}

export interface ClinicianAvailabilitySlots {
  date: Date;
  times: Date[];
}

export interface Clinician {
  firstName: string;
  lastName: string;
  fullName: string;
  userId: number;
  title: string;
  imageUrl: string;
  imageId: string;
  aboutMe: string;
  marketingProfileUrl: string;
  pronouns: string;
  videoUrl: string;
  slots: ClinicianAvailabilitySlots[];
  employmentStatus?: "fulltime" | "parttime" | "contract" | string | null;
  email?: string;
  usesSchedulingTeam?: boolean;
}
