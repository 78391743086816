import { Dialog, Transition } from "@headlessui/react";
import cn from "classnames";
import { Fragment } from "react";

import Modal from "./Modal";

interface ModalSheetProps {
  isOpen?: boolean;
  onClose: () => void;
  children: React.ReactNode;
  stack?: 1 | 2 | 3 | 4;
  size?: "md" | "lg" | "xl";
  disablePadding?: boolean;
  disableCloseButton?: boolean;
}

const STACK = {
  1: {
    overlayZ: "z-20",
    bodyZ: "z-30",
  },
  2: {
    overlayZ: "z-30",
    bodyZ: "z-40",
  },
  3: {
    overlayZ: "z-40",
    bodyZ: "z-50",
  },
  4: {
    overlayZ: "z-50",
    bodyZ: "z-60",
  },
};

const ModalSheet = ({
  isOpen,
  onClose,
  children,
  stack = 1,
  size = "md",
  disablePadding = false,
  disableCloseButton = false,
}: ModalSheetProps) => {
  return (
    <Transition appear as={Fragment} show={isOpen}>
      <Dialog onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={cn(
              STACK[stack].overlayZ,
              "fixed inset-0 bg-gray-900 bg-opacity-25"
            )}
          />
        </Transition.Child>

        <Transition.Child
          className={cn(
            STACK[stack].bodyZ,
            "fixed inset-0 w-screen overflow-y-auto pt-6 phone:pt-0"
          )}
          enter="ease-out duration-1000"
          enterFrom="opacity-0 translate-y-full phone:translate-y-0"
          enterTo="opacity-100 translate-y-0"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-full phone:translate-y-0"
        >
          <div className="grid min-h-full grid-rows-[1fr_auto] justify-items-center phone:grid-rows-[1fr_auto_3fr] phone:p-4">
            <Transition.Child
              as={Dialog.Panel}
              className={cn(
                {
                  "phone:max-w-[640px]": size === "md",
                  "phone:max-w-[768px]": size === "lg",
                  "phone:max-w-[1024px]": size === "xl",
                },
                {
                  "p-6 phone:px-8 phone:py-16": !disablePadding,
                },
                "relative row-start-2 w-full min-w-0 rounded-t-3xl bg-white shadow-lg ring-1 ring-gray-900/10 [--gutter:theme(spacing.8)] phone:mb-auto phone:rounded-2xl"
              )}
              enter="ease-out duration-100"
              enterFrom="phone:scale-95"
              enterTo="phone:scale-100"
              leave="ease-in duration-100"
              leaveFrom="phone:scale-100"
              leaveTo="phone:scale-100"
            >
              <div className="mt-6">
                {!disableCloseButton ? (
                  <Modal.CloseButton onClick={onClose} />
                ) : null}
                {children}
              </div>
            </Transition.Child>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};

export default ModalSheet;
