export interface Notification {
  isRead: boolean;
  type: string;
  message: string;
  sourceUser: {
    handle: string;
    avatarUrl: string;
    id: number;
  };
  age: string;
  threadId: number;
  postId: number;
  createdAt: Date;
  key: string;
}

export enum NotificationTypes {
  FLAG = "flag",
  LIKE = "like",
  REPLY = "reply",
}
