import cn from "classnames";
import { FC, ReactElement, RefObject, useEffect, useRef } from "react";

export interface WithInputRef {
  inputRef: RefObject<HTMLInputElement>;
}

export interface Props {
  prefix?: ReactElement;
  prefixClassName?: string;
  suffix?: ReactElement;
  suffixClassName?: string;
}

const isRefObject = <T,>(value: unknown): value is RefObject<T> =>
  !!(value && (value as RefObject<unknown>).current);

const InputWrappper: FC<Props & WithInputRef> = ({
  inputRef,
  prefix,
  prefixClassName = "px-2.5",
  suffix,
  suffixClassName = "px-2.5",
  children,
}) => {
  const prefixRef = useRef<HTMLDivElement>();
  const suffixRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (isRefObject(prefixRef) && isRefObject(inputRef)) {
      const { width } = prefixRef.current.getBoundingClientRect();
      // eslint-disable-next-line no-param-reassign
      inputRef.current.style.paddingRight = `${width}px`;
    }

    if (isRefObject(suffixRef) && isRefObject(inputRef)) {
      const { width } = suffixRef.current.getBoundingClientRect();
      // eslint-disable-next-line no-param-reassign
      inputRef.current.style.paddingRight = `${width}px`;
    }
  }, [inputRef]);

  return (
    <div className="relative w-full">
      {prefix && (
        <div
          className={cn(
            "absolute top-0 bottom-0 left-0 flex items-center justify-center",
            prefixClassName
          )}
          ref={prefixRef}
        >
          {prefix}
        </div>
      )}

      {children}

      {suffix && (
        <div
          className={cn(
            "absolute top-0 bottom-0 right-0 flex items-center justify-center",
            suffixClassName
          )}
          ref={suffixRef}
        >
          {suffix}
        </div>
      )}
    </div>
  );
};

export default InputWrappper;
