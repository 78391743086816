import { useSession } from "@core/hooks/useSession";
import { LinkedAccount, User } from "@core/hooks/useUser";
import ArrowRightIcon from "@core/icons/heroiconsV2/ArrowRightIcon";
import CheckCircleIcon from "@core/icons/heroiconsV2/CheckCircleIcon";
import { api } from "@core/services/nocd-api";
import Avatar from "@core/ui/Avatar";
import Modal, { useModal } from "@core/ui/Modal";
import {
  useZippedData,
  ZippedData,
} from "@features/therapy/hooks/useTherapyHomeData";
import { signIn, signOut } from "next-auth/react";

function filterZippedData(zippedData: ZippedData[], user: User) {
  // only need to show root + current user for a linked account (they can't see other linked accounts)
  if (!user?.isRootAccount) {
    return zippedData.filter(
      (z) =>
        z.linkedAccount?.user_id === user.id ||
        z.linkedAccount?.is_root_account === 1
    );
  }

  // root can see everything
  return zippedData;
}

interface LinkedAccountSwitcherModalProps {
  isOpen: boolean;
  handleClose: () => void;
  user: User;
}

export default function AccountSwitchModal({
  isOpen,
  handleClose,
  user,
}: LinkedAccountSwitcherModalProps): JSX.Element {
  const { focusRef } = useModal();
  const session = useSession();
  const { data: zippedData } = useZippedData();

  const filteredZippedData = filterZippedData(zippedData, user);

  const switchAccount = async (
    account: LinkedAccount,
    currentAccessToken: string
  ) => {
    if (
      account.prompt_for_email_and_password_to_switch ||
      account?.is_private_linked_account
    ) {
      await signOut({
        callbackUrl: `/login?presetEmail=${account.user_email}`,
      });
    } else {
      const {
        data: { access_token: newAccessToken },
      } = await api.post<{ access_token: string }>(
        `/v1/linkedaccount/member/change_account`,
        { connected_account_user_id: account.user_id },
        {
          headers: {
            Authorization: currentAccessToken,
          },
        }
      );
      await signIn("oob", {
        accessToken: newAccessToken,
        redirect: true,
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      initialFocus={focusRef}
      backgroundColor="gray"
      size="medium"
      className="!m-0 w-full overflow-hidden rounded-lg !p-0 tablet:!max-w-[682px] font-poppins"
    >
      <div className="flex p-6">
        <div>
          <h1 className="text-lg font-bold text-gray-900 text-22px">
            Switch Profiles
          </h1>
          <h2 className="text-base text-gray-800 text-16px">
            {
              user?.linkedAccounts?.find((la) => la.user_id === user.id)
                ?.member_preferred_first_name
            }
            , who would you like to view info for?
          </h2>
        </div>
        <Modal.CloseButton onClick={handleClose} />
      </div>
      <div className="flex flex-col gap-2 p-4">
        {filteredZippedData.map((z) => {
          return (
            <button
              type="button"
              key={z?.linkedAccount.user_id}
              onClick={() =>
                switchAccount(z.linkedAccount, session?.data?.accessToken)
              }
              disabled={z?.linkedAccount.user_id === user.id}
            >
              <div
                className={`flex justify-between border-solid border-2 border-gray-200 rounded-lg p-4 ${
                  z?.linkedAccount.user_id !== user?.id && "hover:bg-teal-300"
                }`}
              >
                <div className="flex items-center gap-4">
                  <Avatar
                    src={z?.linkedAccount?.avatar_url}
                    alt={z?.linkedAccount.member_preferred_first_name}
                    className="w-16 h-16"
                  />
                  <div className="flex flex-col">
                    <h3 className="font-bold text-gray-900 text-22px self-start">
                      {z?.linkedAccount.member_preferred_first_name}
                    </h3>
                    {(z?.linkedAccount?.outstanding_tasks_count > 0 ||
                      z?.linkedAccount?.unread_messages_count > 0) && (
                      <div className="flex gap-2">
                        {z?.linkedAccount?.unread_messages_count > 0 && (
                          <p className="bg-indigo-500 text-white font-bold text-12px px-[8px] py-[2px] rounded-md uppercase">
                            {z.linkedAccount.unread_messages_count} Messages
                          </p>
                        )}
                        {z?.linkedAccount?.outstanding_tasks_count > 0 && (
                          <p className="bg-indigo-500 text-white font-bold text-12px px-[8px] py-[2px] rounded-md uppercase">
                            {z.linkedAccount.outstanding_tasks_count} Tasks
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                {z?.linkedAccount.user_id === user.id ? (
                  <div className="flex items-center gap-4">
                    <h4 className="uppercase text-teal-500 font-bold text-14px">
                      viewing
                    </h4>
                    <CheckCircleIcon className="w-6 h-6 text-teal-500" />
                  </div>
                ) : (
                  <div className="flex items-center">
                    <ArrowRightIcon className="w-6 h-6 text-gray-900 mr-6" />
                  </div>
                )}
              </div>
            </button>
          );
        })}
      </div>
    </Modal>
  );
}
