import Input, { InputProps } from "@core/ui/Input";
import cn from "classnames";
import { forwardRef, ReactNode } from "react";

interface TextFieldProps extends Omit<InputProps, "className"> {
  id: string;
  name: string;
  label: ReactNode;
  hideLabel?: boolean;
  /**
   * If an error message is present, the input will be styled as invalid. If
   * there are no validation errors, make sure to pass `undefined`.
   */
  errorMessage?: string;
  helperText?: ReactNode;
  /**
   * Style individual pieces of the component
   */
  classes?: {
    root?: string;
    label?: string;
    error?: string;
    input?: string;
    helperText?: string;
  };
  labelSuffix?: "required" | "optional" | null;
}

const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      id,
      name,
      label,
      labelSuffix,
      hideLabel = false,
      helperText,
      errorMessage,
      classes,
      ...inputProps
    },
    ref
  ) => {
    const hasError = !!errorMessage;
    const errorMessageId = `${name}-error`;
    const helperTextId = `${name}-helper-text`;

    return (
      <div className={cn(classes?.root)}>
        <label
          htmlFor={name}
          className={cn(
            "mb-1 block text-[1em] font-semibold text-gray-800",
            classes?.label,
            hideLabel && "sr-only"
          )}
        >
          {label}
          {labelSuffix === "required" ? (
            <span className="text-red-600">*</span>
          ) : null}
          {labelSuffix === "optional" ? (
            <span className="text-[0.75em] font-normal uppercase tracking-wide text-gray-600">
              {" "}
              (Optional)
            </span>
          ) : null}
        </label>

        <Input
          {...inputProps}
          id={id}
          className={cn(classes?.input)}
          hasError={hasError}
          name={name}
          ref={ref}
          aria-describedby={
            // eslint-disable-next-line no-nested-ternary
            hasError ? errorMessageId : helperText ? helperTextId : undefined
          }
        />

        {hasError && (
          <span
            id={errorMessageId}
            className={cn(
              "mt-1 block text-[0.875em] text-red-700",
              classes?.error
            )}
          >
            {errorMessage}
          </span>
        )}

        {!hasError && !!helperText && (
          <span
            id={helperTextId}
            className={cn(
              "mt-1 block text-[0.875em] text-gray-600",
              classes?.helperText
            )}
          >
            {helperText}
          </span>
        )}
      </div>
    );
  }
);

TextField.displayName = "TextField";

export default TextField;
