import {
  CheckCircleIcon,
  ExclamationIcon,
  InformationCircleIcon,
} from "@heroicons/react/solid";
import cn from "classnames";
import { ReactNode } from "react";

import Button from "../Button";

const DEFAULT_ICON_CLASSES = "w-6 h-6 flex-shrink-0 ";

type Severity = "info" | "warning" | "error" | "success";

export interface GradientAlertProps {
  severity?: Severity;
  message: ReactNode;
  className?: string;
  callToAction?: {
    onClick: () => void;
    text: ReactNode;
  };
  title?: string;
}

const getGradientBySeverity = (severity: Severity) => {
  switch (severity) {
    case "error":
      return "linear-gradient(to right, #FF5D47, #CEABFD)";
    case "warning":
      return "linear-gradient(to right, #8CC6FC, #FFAFA4, #F8C46E)";
    case "info":
      return "linear-gradient(to right, #6FB8FC, #DAC0FE)";
    case "success":
      return "linear-gradient(to right, #8CC6FC, #FFE29F)";
    default:
      return "linear-gradient(to right, #8CC6FC, #FFE29F)";
  }
};

const getButtonColorBySeverity = (severity: Severity) => {
  switch (severity) {
    case "warning":
      return "light-yellow";
    case "info":
      return "light-blue";
    case "error":
      return "red";
    case "success":
      return "teal";
    default:
      throw new Error("Invalid severity");
  }
};

const GradientAlert = ({
  severity = "warning",
  message,
  callToAction,
  className,
  title,
}: GradientAlertProps) => {
  const gradient = getGradientBySeverity(severity);
  return (
    <div
      className={cn("relative w-full", className)}
      style={{
        background: `linear-gradient(white, white) padding-box, ${gradient} border-box`,
        borderRadius: "8px",
        backgroundSize: "cover",
        border: "1px solid transparent",
        padding: "1px",
        boxSizing: "border-box",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div
        className="tablet:flex-row block w-full rounded-lg p-4 bg-white space-y-0 tablet:space-y-4"
        style={{
          borderRadius: "8px",
          boxSizing: "border-box",
          border: "1px solid transparent",
        }}
      >
        <div className="flex tablet:flex-row flex-col flex-grow tablet:space-x-2 tablet:justify-between items-start">
          <div className=" flex flex-row tablet:flex-row">
            {severity === "warning" && (
              <ExclamationIcon
                className={cn(DEFAULT_ICON_CLASSES, "text-yellow-700")}
              />
            )}
            {severity === "info" && (
              <InformationCircleIcon
                className={cn(DEFAULT_ICON_CLASSES, "text-[#2E8EFF]")}
              />
            )}
            {severity === "error" && (
              <ExclamationIcon
                className={cn(DEFAULT_ICON_CLASSES, "text-red-700")}
              />
            )}
            {severity === "success" && (
              <CheckCircleIcon
                className={cn(DEFAULT_ICON_CLASSES, "text-teal-700")}
              />
            )}

            <div className="flex flex-col ml-2 flex-grow">
              {title && (
                <p className="text-base font-semibold text-16px text leading-[130%] ">
                  {title}
                </p>
              )}
              <p
                className={cn(
                  "font-inter text-13px font-normal",
                  title ? "mt-2 -ml-7" : "leading-[130%] text-base"
                )}
              >
                {message}
              </p>
            </div>
          </div>

          {callToAction?.onClick && callToAction?.text && (
            <Button
              onClick={callToAction.onClick}
              color={getButtonColorBySeverity(severity)}
              className={cn(
                "text-14px w-full tablet:w-auto h-44px font-semibold whitespace-nowrap tablet:mt-0 mt-2 rounded-lg tablet:px-4 ml-0 tablet:ml-4",
                {
                  "border border-teal-300 bg-teal-100": severity === "success",
                  "bg-red-100 text-red-800 hover:bg-red-200":
                    severity === "error",
                  "bg-[#EDF5FE] border-[#2E8EFF] text-[#2E8EFF] hover:border-[#2B88DE] hover:bg-[#EDF5FE]":
                    severity === "info",
                  "bg-yellow-100 border-yellow-600 text-yellow-600 hover:border-yellow-700 hover:bg-yellow-200":
                    severity === "warning",
                }
              )}
              variant="contained"
            >
              {callToAction.text}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default GradientAlert;
