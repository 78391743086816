import { useSession } from "@core/hooks/useSession";
import LogRocket from "logrocket";
import { FC, useEffect } from "react";

const InitializeLogRocketUser: FC = () => {
  const { data } = useSession();

  const user = data?.user;
  const email = user?.email;
  const userId = user?.id;

  useEffect(() => {
    if (
      email &&
      userId &&
      process.env.NEXT_PUBLIC_LOGROCKET_PROJECT_ID &&
      process.env.NODE_ENV === "production"
    ) {
      void LogRocket.identify(`${userId}`, {
        email,
        gitCommitSHA: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
      });
    }
  }, [email, userId]);

  return null;
};

export default InitializeLogRocketUser;
