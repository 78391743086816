import { ApiPaginatedPosts, PaginatedPosts } from "../types";
import transformPost from "./transformPost";

const transformPaginatedPosts = (page: ApiPaginatedPosts): PaginatedPosts => {
  return {
    ...page,
    data: page.data.map((post) => transformPost(post)),
  };
};

export default transformPaginatedPosts;
