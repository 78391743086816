import { useIsAuthenticating } from "@core/hooks/useIsAuthenticating";
import useNativeContext from "@core/hooks/useNativeContext";
import { useSession } from "@core/hooks/useSession";
import { APIErrorType } from "@core/services/nocd-api";
import Button from "@core/ui/Button";
import { useAuthenticationModal } from "@features/auth/hooks/useAuthenticationModal";
import Router from "next/router";
import { signOut } from "next-auth/react";
import { useCallback, useEffect } from "react";
import { useQueryClient } from "react-query";

interface ErrorScreenProps {
  error: Error;
  customComponent?: JSX.Element;
}

const getErrorTitle = (name: string, message: string) => {
  if (name === APIErrorType.INVALID_PERMISSIONS) {
    return "You must be logged in to view this page.";
  }

  if (name === APIErrorType.INVALID_ACCESS_TOKEN) {
    return "Your session has expired. Please log out and log back in.";
  }

  if (name === APIErrorType.POST_DOESNT_EXIST) {
    return "This post is no longer available.";
  }

  return message ?? "An unknown error occurred.";
};

interface RecommendedActionProps {
  name: string;
  onLogOut: () => void;
  onLogIn: () => void;
}

const RecommendedAction = ({
  name,
  onLogOut,
  onLogIn,
}: RecommendedActionProps) => {
  if (name === APIErrorType.INVALID_PERMISSIONS) {
    return (
      <Button onClick={onLogIn} className="items-center">
        Log in
      </Button>
    );
  }

  if (name === APIErrorType.INVALID_ACCESS_TOKEN) {
    return (
      <Button onClick={onLogOut} className="items-center">
        Log out
      </Button>
    );
  }

  return (
    <Button onClick={() => Router.push("/")} className="items-center">
      Go back home
    </Button>
  );
};

export default function ErrorScreen({
  error,
  customComponent,
}: ErrorScreenProps): JSX.Element {
  const queryClient = useQueryClient();
  const [_, setIsAuthenticating] = useIsAuthenticating();
  const { openModal: openLoginModal } = useAuthenticationModal();

  const { data: session } = useSession();
  const { deferRoutingToWebView } = session ?? {};
  const { close: closeWebView } = useNativeContext();

  const handleLogout = useCallback(async () => {
    setIsAuthenticating(true);
    await queryClient.cancelQueries();
    await signOut();
  }, [queryClient, setIsAuthenticating]);

  useEffect(() => {
    if (error.name === APIErrorType.INVALID_ACCESS_TOKEN) {
      handleLogout() as unknown as void;
    }
  }, [error.name, handleLogout, error]);

  if (deferRoutingToWebView) {
    return (
      <div className="flex flex-1 items-center justify-center py-32 px-6">
        <div className="flex flex-col items-center space-y-4 text-20px">
          <p className="text-center font-bold max-w-[500px]">
            {error.message ?? "An unknown error occurred."}
          </p>

          {customComponent}

          <Button onClick={closeWebView} className="items-center">
            Go back
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-1 items-center justify-center py-32 px-6">
      <div className="flex flex-col items-center space-y-4 text-20px">
        <p className="text-center font-bold max-w-[500px]">
          {getErrorTitle(error.name, error.message)}
        </p>

        {customComponent}

        <RecommendedAction
          name={error.name}
          onLogOut={handleLogout}
          onLogIn={openLoginModal}
        />
      </div>
    </div>
  );
}
