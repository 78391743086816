import Button from "@core/ui/Button";
import Loader from "@core/ui/Loader";
import cn from "classnames";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";

import { sendPasswordResetEmail } from "../services/nocd-api";

interface ForgotPasswordConfirmationProps {
  goBackToLoginScreen: () => void;
  email: string;
  className?: string;
}

export default function ForgotPasswordConfirmation({
  goBackToLoginScreen,
  email,
  className,
}: ForgotPasswordConfirmationProps): JSX.Element {
  const [isSendingForgotPasswordEmail, setIsSendingForgotPasswordEmail] =
    useState(false);
  const [isResendingForgotPasswordEmail, setIsResendingForgotPasswordEmail] =
    useState(false);

  useEffect(() => {
    setIsSendingForgotPasswordEmail(true);
    sendPasswordResetEmail(email)
      .catch((error: Error) =>
        toast.error(error?.message ?? "An unknown error occurred")
      )
      .finally(() => setIsSendingForgotPasswordEmail(false));
  }, [email]);

  if (isSendingForgotPasswordEmail) {
    return (
      <div className="flex items-center justify-center py-16">
        <Loader className="text-32px text-teal-600" />
      </div>
    );
  }

  return (
    <div className={cn("mx-auto font-poppins", className)}>
      <h2 className="mb-2 text-center text-24px font-bold text-gray-900 tablet:text-32px text-indigo-600">
        Check your email.
      </h2>

      <p className="mb-12 text-center text-16px text-gray-600 tablet:text-20px">
        We sent you an email with a link to reset your password. When
        you&apos;re done resetting your password, click the button below to try
        logging in again.
      </p>

      <div className="flex flex-col items-center">
        <Button className="mb-6 w-full text-20px" onClick={goBackToLoginScreen}>
          Go back
        </Button>

        <div className="text-center text-gray-700">
          Didn&apos;t receive an email?{" "}
          <Button
            variant="text"
            disabled={isResendingForgotPasswordEmail}
            onClick={() => {
              setIsResendingForgotPasswordEmail(true);

              const promise = sendPasswordResetEmail(email).then(() =>
                setIsResendingForgotPasswordEmail(false)
              );

              toast.promise(promise, {
                success: "Password reset email resent",
                loading: "Resending password reset email...",
                error: (error: Error) => error.message,
              }) as unknown as void;

              return promise;
            }}
          >
            Click here to resend one
          </Button>
        </div>
      </div>
    </div>
  );
}
