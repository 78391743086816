import cn from "classnames";
import { forwardRef, ReactNode } from "react";

interface ContainerProps {
  children: ReactNode;
  className?: string;
  disablePadding?: boolean;
  disableMaxWidth?: boolean;
}
const Container = forwardRef<HTMLDivElement, ContainerProps>(
  ({ children, className, disablePadding, disableMaxWidth }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          "mx-auto flex-1",
          {
            "px-4 laptop:px-6": !disablePadding,
            "max-w-[1072px]": !disableMaxWidth,
          },
          className
        )}
      >
        {children}
      </div>
    );
  }
);

Container.displayName = "Container";

export default Container;
