import UserIcon from "@core/icons/UserIcon";
import { Size } from "@core/types";
import cn from "classnames";
import { FC, useEffect, useMemo, useState } from "react";

export interface Props {
  src?: string;
  alt?: string;
  size?: Size;
  className?: string;
}

enum LoadStatus {
  LOADING,
  SUCCESS,
  ERROR,
}

// Display the avatar for a user, optionally fallback when no src available
const Avatar: FC<Props> = ({
  src,
  alt,
  className,
  size = "medium",
  children,
}) => {
  const [loadStatus, setLoadStatus] = useState(LoadStatus.LOADING);

  const loadFailed = loadStatus === LoadStatus.ERROR;
  const loadSuccess = loadStatus === LoadStatus.SUCCESS;

  const fallback = useMemo(() => {
    if (typeof alt === "string") {
      return alt
        .split(" ")
        .slice(0, 2)
        .map((str) => str[0])
        .join("");
    }
    return null;
  }, [alt]);

  const hasCustomBackgroundColor = useMemo(() => {
    if (typeof className !== "string") {
      return false;
    }
    return className.includes("bg-");
  }, [className]);

  // add listeners to the avatar's image to check if the load fails/succeeds
  useEffect(() => {
    if (typeof src !== "string") {
      return;
    }

    let mounted = true;

    const handleError = () => {
      if (!mounted) {
        return;
      }
      setLoadStatus(LoadStatus.ERROR);
    };

    const handleLoad = () => {
      if (!mounted) {
        return;
      }
      setLoadStatus(LoadStatus.SUCCESS);
    };

    const image = new Image();

    image.onerror = handleError;
    image.onload = handleLoad;
    // src needs to be last, can cause issues with detecting the load event when
    // the image is cached. https://github.com/mui-org/material-ui/pull/25793
    image.src = src;

    // eslint-disable-next-line consistent-return
    return () => {
      mounted = false;
    };
  }, [src, setLoadStatus]);

  return (
    <div
      className={cn(
        "flex items-center justify-center overflow-hidden rounded-full",
        {
          "h-[35px] w-[35px] text-20px": size === "small",
          "h-[55px] w-[55px] text-24px": size === "medium",
          "h-[75px] w-[75px] text-24px": size === "large",
          "h-[95px] w-[95px] text-26px": size === "extraLarge",
          "bg-cover": !!src,
          // if the load failed and the caller already hasn't specified a
          // background color for the avatar, default to the lightest blue
          // color (arbitrarily chosen)
          "bg-blue-100": !hasCustomBackgroundColor && loadFailed,
          "bg-white": loadStatus === LoadStatus.LOADING,
        },
        className
      )}
      style={{
        backgroundImage: src && loadSuccess && `url(${src})`,
        backgroundRepeat: "no-repeat",
      }}
      role="img"
      title={alt}
    >
      {!src && children}
      {(!src || loadFailed) && !children && (
        <span className="text-16px font-bold uppercase">{fallback}</span>
      )}
      {(!src || loadFailed) && !fallback && !children && (
        <UserIcon fontSize="inherit" />
      )}
    </div>
  );
};

export default Avatar;
