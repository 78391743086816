import { api } from "@core/services/nocd-api";

interface DefaultResponse {
  status: string;
}

export const sendOneTimeCodeToExistingUser = (
  emailAddress: string
): Promise<DefaultResponse> =>
  api
    .post<DefaultResponse>("/v1/members/send_code", { email: emailAddress })
    .then(({ data }) => data);

export const testOneTimeCodeForExistingUser = (
  emailAddress: string,
  oneTimeCode: string
): Promise<DefaultResponse> =>
  api
    .post<DefaultResponse>("/v1/members/test_code", {
      email: emailAddress,
      loginCode: oneTimeCode,
    })
    .then(({ data }) => data);

export const resetPasswordForExistingUser = (
  emailAddress: string,
  newPassword: string,
  oneTimeCode: string
): Promise<DefaultResponse> =>
  api
    .post<DefaultResponse>("/v1/members/reset_password", {
      email: emailAddress,
      loginCode: oneTimeCode,
      password: newPassword,
    })
    .then(({ data }) => data);

interface CreateUserResponse {
  needs_email_verification: boolean;
  email_verification_message: string;
}

interface CreateUserArgs {
  email: string;
  password: string;
  oneTimeCode?: string;
  username: string;
  deviceId: string;
  sessionId: string;
  source?: string;
}

export const createUser = ({
  email,
  password,
  oneTimeCode,
  username,
  deviceId,
  sessionId,
  source,
}: CreateUserArgs): Promise<CreateUserResponse> =>
  api
    .post<CreateUserResponse>(
      "/v3/register",
      {
        email,
        password,
        check_risk: true,
        verify_code: oneTimeCode,
        user_handle: username,
        source,
      },
      {
        headers: {
          "X-DeviceID": deviceId,
          "X-SessionID": sessionId,
        },
      }
    )
    .then(({ data }) => data);

export const sendOneTimeCodeToNewUser = (
  emailAddress: string
): Promise<DefaultResponse> =>
  api
    .post<DefaultResponse>("/v3/resend_verification_code", {
      email: emailAddress,
    })
    .then(({ data }) => data);

interface ValidateUsernameResponse {
  is_valid: boolean;
  isValid: boolean;
  message: string;
}

export const validateUsername = (
  username: string
): Promise<ValidateUsernameResponse> =>
  api
    .post<ValidateUsernameResponse>("/v1/validate_handle", {
      userHandle: username,
    })
    .then(({ data }) => data);

interface RegisterDeviceArgs {
  sessionId: string;
  locale?: string;
  timeZone?: string;
}

interface RegisterDeviceResponse {
  sessionID: string;
  deviceID: string;
}

export const registerDevice = ({
  sessionId,
  locale,
  timeZone,
}: RegisterDeviceArgs): Promise<RegisterDeviceResponse> =>
  api
    .post<RegisterDeviceResponse>(
      "/v1/device_registration",
      {
        deviceType: "web",
        locale,
        timeZone,
      },
      {
        headers: {
          "X-SessionID": sessionId,
        },
      }
    )
    .then(({ data }) => data);

interface SendPasswordResetEmailResponse {
  status: number;
  msg: string;
}

export const sendPasswordResetEmail = (
  email: string
): Promise<SendPasswordResetEmailResponse> =>
  api
    .post<SendPasswordResetEmailResponse>("/v1/reset", {
      email,
    })
    .then(({ data }) => data);

export enum CheckEmailStatuses {
  NEEDS_REGISTER = "needs_register",
  NEEDS_LOGIN = "needs_login",
  NEEDS_PASSWORD_RESET = "needs_password_reset",
}

interface CheckEmailResponse {
  status: CheckEmailStatuses;
}

export const checkEmail = (email: string): Promise<CheckEmailResponse> =>
  api
    .get<CheckEmailResponse>("/v2/members/email", {
      params: { email },
    })
    .then(({ data }) => data);
