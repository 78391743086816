import useForkRef from "@core/hooks/useForkRef";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import cn from "classnames";
import {
  forwardRef,
  InputHTMLAttributes,
  useCallback,
  useRef,
  useState,
} from "react";

import InputWrappper, { Props as InputWrapperProps } from "./Wrapper";

type InputElementProps = Omit<InputHTMLAttributes<HTMLInputElement>, "prefix">;

export type InputProps = {
  hasError?: boolean;
  variant?: "outlined" | "filled";
  backgroundColor?: "gray" | "white";
  disabled?: boolean;
} & InputElementProps;

export const InnerInput = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      hasError,
      backgroundColor = "gray",
      disabled = false,
      variant = "filled",
      ...props
    },
    ref
  ) => {
    return (
      <input
        {...props}
        disabled={disabled}
        ref={ref}
        aria-invalid={hasError}
        className={cn(
          className,
          "block w-full appearance-none rounded-lg border px-[1em] py-[0.75em] text-[1em] text-gray-800 placeholder-opacity-60",
          {
            "border-red-700 focus:border-red-700 focus:ring-2 focus:ring-red-700":
              hasError,
            "focus:ring-2 focus:ring-teal-600": !hasError,
            "opacity-70": disabled,

            // Outlined
            "border-teal-600 bg-white focus:border-teal-700":
              variant === "outlined",

            // Filled
            "border-transparent focus:border-transparent":
              variant === "filled" && !hasError,
            "bg-white": backgroundColor === "white" && variant === "filled",
            "bg-gray-100": backgroundColor === "gray" && variant === "filled",
          }
        )}
      />
    );
  }
);

InnerInput.displayName = "InnerInput";

const PasswordInput = forwardRef<HTMLInputElement, InputProps>(
  (props, forwardedRef) => {
    const inputRef = useRef<HTMLInputElement>();
    const ref = useForkRef(forwardedRef, inputRef);

    const [isVisible, setIsVisible] = useState(false);

    const handleClick = useCallback(() => {
      setIsVisible((prev) => !prev);
    }, [setIsVisible]);

    return (
      <InputWrappper
        suffix={
          <button type="button" onClick={handleClick}>
            {isVisible ? (
              <EyeOffIcon className="h-[1.5em] w-[1.5em] text-gray-700" />
            ) : (
              <EyeIcon className="h-[1.5em] w-[1.5em] text-gray-700" />
            )}
          </button>
        }
        inputRef={inputRef}
      >
        <InnerInput
          {...props}
          ref={ref}
          type={isVisible ? "text" : "password"}
        />
      </InputWrappper>
    );
  }
);

PasswordInput.displayName = "PasswordInput";

const BaseInput = forwardRef<HTMLInputElement, InputProps & InputWrapperProps>(
  (
    { type, prefix, prefixClassName, suffix, suffixClassName, ...props },
    forwardedRef
  ) => {
    const inputRef = useRef<HTMLInputElement>();
    const ref = useForkRef(forwardedRef, inputRef);

    return (
      <InputWrappper
        prefix={prefix}
        suffix={suffix}
        prefixClassName={prefixClassName}
        suffixClassName={suffixClassName}
        inputRef={inputRef}
      >
        <InnerInput {...props} ref={ref} type={type} />
      </InputWrappper>
    );
  }
);

BaseInput.displayName = "BaseInput";

export const Input = forwardRef<
  HTMLInputElement,
  InputProps & InputWrapperProps
>(({ type, ...props }, ref) => {
  if (type === "password") {
    return <PasswordInput {...props} ref={ref} />;
  }

  return <BaseInput {...props} type={type} ref={ref} />;
});

Input.displayName = "Input";
