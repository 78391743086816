export interface FormValues {
  email: string;
  password: string;
  oneTimeCode?: string;
  emailAddress?: string;
  newPassword?: string;
}

export enum DeviceTypes {
  WEB_VIEW = "web_view",
}
