import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

interface Store {
  previousPath: string;
  currentPath: string;
  setCurrentPath: (currentPath: string) => void;
}

// Browsers don't allow us to see if there is any history in the browser, so
// we have to store it ourselves in session storage.
const useCanGoBack = create<Store>()(
  persist(
    (set) => ({
      previousPath: null,
      currentPath: null,
      setCurrentPath: (currentPath: string) =>
        set((state) => ({ currentPath, previousPath: state.currentPath })),
    }),
    {
      name: "use-can-go-back",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export default useCanGoBack;
