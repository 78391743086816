import { useSession } from "@core/hooks/useSession";
import { api } from "@core/services/nocd-api";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";

import getQueryKeys from "../query-keys";
import { Notification } from "../types";

type MutationContext = {
  previousNotifications: Notification[];
};

export const useClearNotifications = (): UseMutationResult<
  unknown,
  Error,
  void,
  MutationContext
> => {
  const { data: session } = useSession();
  const { accessToken } = session;
  const queryClient = useQueryClient();

  return useMutation(
    () =>
      api
        .post("/v1/members/mark_all_notifications_as_read", null, {
          headers: { Authorization: accessToken },
        })
        .then((data) => data),
    {
      onSuccess: () => {
        // See if there's any data in the query cache
        const previousNotifications = queryClient.getQueryData(
          getQueryKeys(accessToken).all()
        );

        if (previousNotifications) {
          queryClient.setQueryData<Notification[]>(
            getQueryKeys(accessToken).all(),
            (oldData) => {
              return oldData.map((notification) => {
                return {
                  ...notification,
                  isRead: true,
                };
              });
            }
          );
        }
      },
    }
  );
};
