import { useSession } from "@core/hooks/useSession";
import passwordSchema from "@core/schemas/password";
import usernameSchema from "@core/schemas/username";
import { api } from "@core/services/nocd-api";
import Button from "@core/ui/Button";
import TextField from "@core/ui/TextField";
import { useMutateProfile } from "@features/user-profile/hooks/useMutateProfile";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";

interface Props {
  onSuccess: () => void;
}

const schema = yup.object({
  username: usernameSchema,
  password: passwordSchema,
});

interface FormValues {
  username: string;
  password: string;
}

const SetPasswordForm: FC<Props> = ({ onSuccess }) => {
  const { data: session } = useSession();
  const accessToken = session?.accessToken;
  const email = session?.user?.email;
  const userId = session?.user?.id;

  const { mutate } = useMutateProfile(userId as unknown as string);

  const onSubmit = useCallback(
    ({ password, username }: FormValues) => {
      return api
        .post(
          "/v1/auth/set_password",
          { password, email },
          { headers: { Authorization: accessToken } }
        )
        .then(() => {
          return mutate({ community_handle: username });
        })
        .then(onSuccess)
        .catch(() => {
          toast.error("An unexpected error occurred.");
        });
    },
    [email, onSuccess, accessToken, mutate]
  );

  const form = useForm<FormValues>({
    defaultValues: {
      username: "",
      password: "",
    },
    resolver: yupResolver(schema),
  });
  const { register, formState } = form;
  const { errors, isSubmitting } = formState;

  const handleSubmit = form.handleSubmit(onSubmit);

  return (
    <div className="mb-8">
      <form onSubmit={handleSubmit}>
        <div className="mb-12 flex flex-col items-center space-y-8">
          <div className="w-full">
            <TextField
              classes={{
                root: "text-16px laptop:text-20px",
              }}
              hideLabel
              id="username"
              {...register("username")}
              label="Username"
              placeholder="Username"
              errorMessage={errors?.username?.message}
            />
          </div>

          <div className="w-full">
            <TextField
              classes={{
                root: "text-16px laptop:text-20px",
              }}
              hideLabel
              id="password"
              type="password"
              {...register("password")}
              label="Password"
              placeholder="Password"
              autoComplete="new-password"
              errorMessage={errors.password?.message}
            />
          </div>
        </div>
        <div className="flex flex-col items-center">
          <Button
            type="submit"
            loading={isSubmitting}
            disabled={isSubmitting}
            className="mb-6 w-full text-20px tablet:w-auto"
          >
            Next
          </Button>
        </div>
      </form>
    </div>
  );
};

export default SetPasswordForm;
