import Button from "@core/ui/Button";
import HorizontalDivider from "@core/ui/HorizontalDivider";
import TextField from "@core/ui/TextField";
import { yupResolver } from "@hookform/resolvers/yup";
import cn from "classnames";
import { trim } from "lodash/fp";
import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import * as yup from "yup";

import { checkEmail, CheckEmailStatuses } from "../services/nocd-api";
import GoogleSignInButton from "./GoogleSignInButton";

interface CheckEmailFormProps {
  onSuccess: ({
    email,
    status,
  }: {
    email: string;
    status: CheckEmailStatuses;
  }) => void;
  className?: string;
  heading: string;
  subHeading: string;
}

const schema = yup.object({
  email: yup
    .string()
    .trim()
    .email("Enter a valid email address")
    .required("Enter your email address"),
});
type FormValues = yup.TypeOf<typeof schema>;

export default function CheckEmailForm({
  onSuccess,
  className,
  heading,
  subHeading,
}: CheckEmailFormProps): JSX.Element {
  const form = useForm<FormValues>({
    resolver: yupResolver(schema),
  });

  const { register, formState } = form;
  const { isSubmitting, errors } = formState;

  const onSubmit = form.handleSubmit(({ email }: FormValues) => {
    const sanitizedEmail = trim(email);

    return checkEmail(sanitizedEmail)
      .then(({ status }) =>
        onSuccess({
          email: sanitizedEmail,
          status,
        })
      )
      .catch((error: Error) => toast.error(error.message));
  });

  return (
    <form onSubmit={onSubmit} className={cn("font-poppins mx-auto", className)}>
      <h2 className="text-24px tablet:text-32px mb-2 text-center font-bold leading-tight text-indigo-600">
        {heading}
      </h2>

      <p className="text-16px tablet:text-20px mb-12 text-center text-gray-600">
        {subHeading}
      </p>

      <div className="mb-12">
        <TextField
          classes={{
            root: "text-16px laptop:text-20px",
          }}
          hideLabel
          placeholder="Email"
          id="email"
          name="email"
          type="text"
          label="Email"
          autoComplete="email"
          autoCapitalize="off"
          autoCorrect="off"
          inputMode="email"
          errorMessage={errors.email?.message}
          {...register("email")}
        />
      </div>

      <div className="flex flex-col items-center">
        <Button
          disabled={isSubmitting}
          type="submit"
          loading={isSubmitting}
          className="text-20px mb-6 w-full"
        >
          Next
        </Button>
      </div>

      <div className="flex flex-col items-center">
        <HorizontalDivider className="mb-8 mt-6 w-full">OR</HorizontalDivider>

        <GoogleSignInButton.Bound />
      </div>
    </form>
  );
}
