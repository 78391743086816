import { useSession } from "@core/hooks/useSession";
import { api } from "@core/services/nocd-api";
import { AxiosError } from "axios";
import { omit } from "lodash/fp";
import { useQuery, UseQueryResult } from "react-query";

import {
  ClinicianProfile,
  Interest,
  Question,
  Subtype,
  UserProfile,
  UserType,
} from "../types";

interface ClinicianProfileDTO {
  name: string;
  about: string | null;
  avatar_url: string;
  can_edit: boolean;
  community_handle: string | null;
  cta_text: string;
  cta_url: string;
  education: string | null;
  states: string[];
  ocd_experience: string | null;
  title: string | null;
  user_id: string;
  user_profile_type: UserType.CLINICIAN;
  video_url: string | null;
}

interface MemberProfileDTO {
  avatar_url: string;
  community_handle: string | null;
  user_profile_type: UserType.MEMBER | UserType.ADVOCATE;
  caption: string | null;
  show_caption_on_profile: boolean;
  about: string | null;
  show_about_on_profile: boolean;
  bio_image_url: string | null;
  subtypes: Array<SubtypeDTO>;
  show_subtypes_on_profile: boolean;
  interests: Array<{ id: number; interest: string; selected: boolean }>;
  show_interests_on_profile: boolean;
  questions: Array<{
    id: number;
    question: string;
    answer: string;
    selected: boolean;
  }>;
  show_questions_on_profile: boolean;
  can_edit: boolean;
  cta_text: string;
  cta_url: string;
  user_id: string;
}

// interface TopicsDTO {
//   more_topics: Array<TopicDTO>;
//   // TODO update this once shape is found in response
//   my_topics: unknown;
// }

// interface TopicDTO {
//   category_title: string;
//   topics: Array<{
//     onboarding_selected: boolean;
//     topic_description: string;
//     topic_id: string;
//     topic_image_url: string;
//     topic_title: string;
//   }>;
// }

export interface SubtypeDTO {
  id: string;
  title: string;
  description: string;
  selected: boolean;
  image_url: string;
}

export interface QuestionDTO {
  id: number;
  question: string;
  answer: string | null;
  selected: boolean;
}

export interface InterestDTO {
  id: number;
  interest: string;
  selected: boolean;
}

export type UserProfileDTO = ClinicianProfileDTO | MemberProfileDTO;

const isClinician = (
  profile: UserProfileDTO
): profile is ClinicianProfileDTO => {
  return profile.user_profile_type === UserType.CLINICIAN;
};

export const transformSubtypeDto = (data: SubtypeDTO): Subtype => {
  return {
    ...data,
    imageUrl: data.image_url,
    isSelected: data.selected,
  };
};

// Normalize the user profile data for presentation
// Different user types have different profile data available + with different
// property names. Get the profile data, but normalize the property names to
// make consuming the profile easier.
export const transformUserProfileDto = (data: UserProfileDTO): UserProfile => {
  if (isClinician(data)) {
    const profile: ClinicianProfile = {
      name: data.name,
      communityHandle: data.community_handle,
      displayName: data.name,
      about: data.about,
      avatarUrl: data.avatar_url,
      canEdit: data.can_edit,
      education: data.education,
      states: data.states,
      ocdExperience: data.ocd_experience,
      headline: data.title,
      userId: data.user_id,
      userType: UserType.CLINICIAN,
      cta: {
        text: data.cta_text,
        url: data.cta_url,
      },
      video: data.video_url ?? null,
    };

    return profile;
  }

  const questions = (data.questions ?? []).map(
    (question): Question => ({
      ...omit("selected", question),
      isSelected: !!question.selected,
    })
  );

  const interests = (data.interests ?? []).map(
    (interest): Interest => ({
      ...omit("selected", interest),
      isSelected: !!interest.selected,
    })
  );

  const subtypes = (data.subtypes ?? []).map((subtype) =>
    transformSubtypeDto(subtype)
  );

  return {
    avatarUrl: data.avatar_url,
    communityHandle: data.community_handle,
    displayName: data.community_handle,
    userType: data.user_profile_type,
    headline: data.caption,
    shouldShowHeadline: data.show_caption_on_profile,
    about: data.about,
    shouldShowAbout: data.show_about_on_profile,
    bioImageUrl: data.bio_image_url,
    subtypes,
    selectedSubtypes: subtypes.filter(({ isSelected }) => isSelected),
    shouldShowSubtypes: data.show_subtypes_on_profile,
    interests,
    selectedInterests: interests.filter(({ isSelected }) => isSelected),
    shouldShowInterests: data.show_interests_on_profile,
    questions,
    selectedQuestions: questions.filter(({ isSelected }) => isSelected),
    shouldShowQuestions: data.show_questions_on_profile,
    canEdit: data.can_edit,
    cta: {
      text: data.cta_text,
      url: data.cta_url,
    },
    userId: data.user_id,
  };
};

export const getUserProfile = async (
  userId: string,
  accessToken: string,
  signal?: AbortSignal
): Promise<UserProfile> => {
  const url = `/v2/community/user_profile${userId ? `?user_id=${userId}` : ""}`;
  return api
    .get<UserProfileDTO>(url, {
      headers: { Authorization: accessToken },
      signal,
    })
    .then(({ data }) => transformUserProfileDto(data));
};

export const getQueryKey = (
  userId: string,
  accessToken: string
): ["user-profile", string, string] => ["user-profile", userId, accessToken];

export const useUserProfile = (
  userId?: string
): UseQueryResult<UserProfile, AxiosError> => {
  const { data: session } = useSession();

  return useQuery(
    getQueryKey(userId, session?.accessToken),
    async ({ signal }) => getUserProfile(userId, session?.accessToken, signal),
    { enabled: !!session?.accessToken && !!userId }
  );
};
