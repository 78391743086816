import { getGoogleAnalyticsClientId } from "@core/services/google-analytics";
import { recordEventInGtm } from "@core/services/gtm";
import Button from "@core/ui/Button";
import TextField from "@core/ui/TextField";
import { yupResolver } from "@hookform/resolvers/yup";
import cn from "classnames";
import { signIn } from "next-auth/react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";

import { resetPasswordForExistingUser } from "../services/nocd-api";

const schema = yup.object({
  password: yup
    .string()
    .required("Enter a password")
    .min(8, "Must be at least 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~!@#])(?=.{8,})/,
      "At least 8 characters, one number (123), one symbol (~!@#), and both lower and uppercase letters (Ab)"
    ),
});
type FormValues = yup.TypeOf<typeof schema>;

interface NewPasswordFormProps {
  email: string;
  oneTimeCode: string;
  onSuccess: () => void;
  onClickBack: () => void;
  className?: string;
  eventToRecord?: string;
}

export default function NewPasswordForm({
  email,
  oneTimeCode,
  onSuccess,
  onClickBack,
  className,
  eventToRecord,
}: NewPasswordFormProps): JSX.Element {
  const form = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const { register, formState } = form;
  const { isValid, isSubmitting, errors } = formState;

  const onSubmit = form.handleSubmit(async ({ password }: FormValues) => {
    try {
      await resetPasswordForExistingUser(email, password, oneTimeCode);
    } catch (error) {
      toast.error(
        error instanceof Error ? error.message : "An unknown error occurred"
      );
      return;
    }

    try {
      await signIn("credentials", {
        redirect: false,
        email,
        password,
        gaClientId: getGoogleAnalyticsClientId(),
      });
    } catch (error) {
      toast.error(
        error instanceof Error ? error.message : "An unknown error occurred"
      );
      return;
    }

    if (eventToRecord) {
      try {
        recordEventInGtm(eventToRecord);
      } catch (gtmError) {
        console.error(
          "Error recording registration in GTM ",
          gtmError instanceof Error ? gtmError.message : ""
        );
      }
    }

    onSuccess();
  });

  return (
    <form onSubmit={onSubmit} className={cn("mx-auto font-poppins", className)}>
      <h2 className="mb-12 text-center text-24px font-bold tablet:text-32px text-indigo-600">
        Choose a password
      </h2>

      <div className="mb-12">
        <TextField
          classes={{
            root: "text-16px laptop:text-20px",
          }}
          placeholder="Password"
          label="Password"
          autoComplete="new-password"
          id="password"
          name="password"
          type="password"
          hideLabel
          errorMessage={errors.password?.message}
          helperText="At least 8 characters, a number (123) or a symbol (~!@#), and both lower and uppercase letters (Ab)"
          {...register("password")}
        />
      </div>

      <div className="grid gap-4">
        <Button
          disabled={isSubmitting || !isValid}
          loading={isSubmitting}
          type="submit"
          className="w-full text-20px tablet:w-auto"
        >
          Sign up
        </Button>

        <Button
          variant="outlined"
          type="button"
          onClick={onClickBack}
          className="w-full text-20px tablet:order-first tablet:w-auto"
        >
          Back
        </Button>
      </div>
    </form>
  );
}
