import Button from "@core/ui/Button";
import cn from "classnames";
import { isEmpty } from "lodash/fp";
import Image from "next/image";
import NextLink from "next/link";
import { ReactNode, useMemo } from "react";

import ArrowRightIcon from "./ArrowRightIcon";
import { NavigationLink } from "./types";

interface MobileMenuProps {
  callToAction: ReactNode;
  ctaButtonLabel: string;
  expandedNavigationLinkId: string;
  isOpen: boolean;
  navigationLinks: NavigationLink[];
  onChangeExpandedNavigationLinkId: (expandedNavigationLinkId: string) => void;
  onClickCTA: () => void;
  onToggleMenu: () => void;
}

export default function MobileMenu({
  callToAction,
  ctaButtonLabel,
  expandedNavigationLinkId,
  isOpen,
  navigationLinks,
  onChangeExpandedNavigationLinkId,
  onClickCTA,
  onToggleMenu,
}: MobileMenuProps): JSX.Element {
  const expandedNavigationLink = useMemo(
    () => navigationLinks.find((item) => item.id === expandedNavigationLinkId),
    [expandedNavigationLinkId, navigationLinks]
  );

  return (
    <>
      <header className="mx-auto flex h-[58px] max-w-[1072px] items-center justify-between space-x-12 px-6 laptop:hidden">
        <button
          type="button"
          className="h-[26px] w-[88px]"
          onClick={() => {
            window.location.href = process.env.NEXT_PUBLIC_WEBSITE_BASE_URL;
            return undefined;
          }}
        >
          <Image
            src="/images/nocd-logo.svg"
            alt="Go to homepage"
            width={120}
            height={35}
            priority
          />
        </button>

        <nav className="flex items-center space-x-4">
          {callToAction}
          <button
            type="button"
            onClick={onToggleMenu}
            aria-label={isOpen ? "Open menu" : "Close menu"}
          >
            <div
              className={cn(
                `mb-2 h-[2px] w-8 rounded bg-teal-600 transition-transform duration-300`,
                isOpen && "translate-y-3 rotate-45 transform"
              )}
            />
            <div
              className={cn(
                `mb-2 h-[2px] w-8 rounded bg-teal-600 transition-transform duration-300`,
                isOpen ? "opacity-0" : "opacity-100"
              )}
            />
            <div
              className={cn(
                `h-[2px] w-8 rounded bg-teal-600 transition-transform duration-300`,
                isOpen && "-translate-y-2 -rotate-45 transform"
              )}
            />
          </button>
        </nav>
      </header>

      <nav
        className={cn(
          "fixed flex min-h-[calc(100%-58px)] w-full transform bg-[#EDF2F7] transition-transform laptop:hidden",
          isOpen ? "translate-x-0" : "translate-x-full"
        )}
      >
        <div className="mx-auto flex max-w-[1072px] flex-1 flex-col px-6 py-3">
          <ul className="flex-1 divide-y-2 divide-[#E2E8F0] text-16px font-bold">
            {navigationLinks
              .filter(({ hide }) => !hide)
              .map(({ Link, title, id, children }) => (
                <li key={id}>
                  <Link className="flex items-center justify-between py-3 font-bold">
                    <span>{title}</span>

                    {children ? (
                      <span className="mb-1">
                        <ArrowRightIcon className="w-2" />
                      </span>
                    ) : null}
                  </Link>
                </li>
              ))}
          </ul>

          <div className="flex flex-col justify-center space-y-4">
            <Button
              disablePadding
              rounded="full"
              className="mx-auto !h-[36px] !min-h-[36px] !w-[162.64px] text-16px"
              onClick={onClickCTA}
            >
              {ctaButtonLabel}
            </Button>

            <a
              href="mailto:care@nocdhelp.com"
              className="text-center text-teal-600"
            >
              Email us at care@nocdhelp.com
            </a>
          </div>
        </div>
      </nav>

      <nav
        className={cn(
          "fixed top-0 flex min-h-screen w-full transform flex-col bg-white transition-transform laptop:hidden",
          isEmpty(expandedNavigationLinkId)
            ? "translate-x-full"
            : "translate-x-0"
        )}
      >
        <div className="mx-auto flex h-[58px] w-full max-w-[1072px] items-center p-6">
          <button
            type="button"
            onClick={() => onChangeExpandedNavigationLinkId("")}
            className="group flex w-full items-center space-x-6 font-bold"
            aria-label={expandedNavigationLink?.title}
          >
            <ArrowRightIcon className="w-2 rotate-180 transform text-teal-600" />
            <span className="transition-colors group-hover:text-teal-600">
              {expandedNavigationLink?.title}
            </span>
          </button>
        </div>

        <div className="mx-auto flex w-full flex-1 bg-[#EDF2F7]">
          <ul className="mx-auto max-w-[1072px] flex-1 divide-y-2 divide-[#E2E8F0] px-6 py-3 text-16px font-bold">
            {expandedNavigationLink?.children.map(
              ({ icon, title, url, isExternal }) => (
                <li key={title}>
                  {isExternal ? (
                    <a
                      href={url}
                      className="flex items-center space-x-2 py-4 transition-colors hover:text-teal-600"
                    >
                      <div className="flex w-8">{icon}</div>

                      <p className="font-bold">{title}</p>
                    </a>
                  ) : (
                    <NextLink href={url}>
                      <a className="flex items-center space-x-2 py-4 transition-colors hover:text-teal-600">
                        <div className="flex w-8">{icon}</div>

                        <p className="font-bold">{title}</p>
                      </a>
                    </NextLink>
                  )}
                </li>
              )
            )}
          </ul>
        </div>
      </nav>
    </>
  );
}
