interface GoogleAnalytics {
  vid: string;
}

declare global {
  interface Window {
    gaGlobal: GoogleAnalytics;
  }
}

export const getGoogleAnalyticsClientId = (): string | undefined => {
  try {
    if (window && window?.gaGlobal) {
      return window?.gaGlobal?.vid;
    }

    return undefined;
  } catch (error) {
    return undefined;
  }
};
