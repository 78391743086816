import {
  Notification as INotification,
  NotificationTypes,
  useMutateNotifications,
} from "@core/services/nocd-api/notifications";
import ChatIcon from "@heroicons/react/solid/ChatIcon";
import FlagIcon from "@heroicons/react/solid/FlagIcon";
import HeartIcon from "@heroicons/react/solid/HeartIcon";
import cn from "classnames";
import Router from "next/router";
import { forwardRef } from "react";

interface Props {
  notification: INotification;
  className?: string;
}

interface NotificationIconProps {
  type: NotificationTypes;
}

export function NotificationIcon({ type }: NotificationIconProps): JSX.Element {
  if (type === NotificationTypes.REPLY) {
    return (
      <ChatIcon className="h-6 w-6 flex-shrink-0 fill-current text-indigo-600" />
    );
  }

  if (type === NotificationTypes.FLAG) {
    return (
      <FlagIcon className="h-6 w-6 flex-shrink-0 fill-current text-red-600" />
    );
  }

  if (type === NotificationTypes.LIKE) {
    return (
      <HeartIcon className="h-6 w-6 flex-shrink-0 fill-current text-indigo-600" />
    );
  }

  return null;
}

const Notification = forwardRef<HTMLButtonElement, Props>(
  ({ notification, className }, ref) => {
    const { mutate } = useMutateNotifications();

    return (
      <button
        ref={ref}
        type="button"
        onClick={() => {
          mutate({
            postId: notification.postId,
            threadId: notification.threadId,
          });
          return Router.push(`/community/posts/${notification.postId}`);
        }}
        className={cn(
          "relative flex w-full items-center py-3 text-left",
          className
        )}
      >
        <NotificationIcon type={notification.type as NotificationTypes} />

        <div
          className={cn(
            "mx-4 text-14px tablet:text-16px",
            notification.isRead ? "text-gray-600" : "text-gray-900 "
          )}
        >
          {notification.message}
        </div>

        <div className="ml-auto flex-shrink-0 text-14px capitalize leading-none text-gray-600 tablet:text-16px">
          {notification.age}
        </div>
      </button>
    );
  }
);

Notification.displayName = "Notification";

export default Notification;
