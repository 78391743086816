import { useSession } from "@core/hooks/useSession";
import { api } from "@core/services/nocd-api";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";

import getQueryKeys from "../query-keys";
import { Notification } from "../types";

type MutationContext = {
  previousNotifications: Notification[];
};

export const useMutateNotifications = (): UseMutationResult<
  unknown,
  Error,
  { postId: string | number; threadId: string | number },
  MutationContext
> => {
  const { data: session } = useSession();
  const { accessToken, deviceId } = session;

  const queryClient = useQueryClient();

  return useMutation(
    ({ postId, threadId }) =>
      api
        .post(`/v1/posts/${threadId}/seen`, undefined, {
          headers: {
            "X-DeviceID": deviceId,
            Authorization: accessToken,
          },
        })
        .then(() => postId),
    {
      onSuccess: (postId) => {
        // See if there's any data in the query cache
        const previousNotifications = queryClient.getQueryData(
          getQueryKeys(accessToken).all()
        );

        if (previousNotifications) {
          queryClient.setQueryData<Notification[]>(
            getQueryKeys(accessToken).all(),
            (oldData) => {
              return oldData.map((notification) => {
                if (+notification.postId === +postId) {
                  return {
                    ...notification,
                    isRead: true,
                  };
                }

                return notification;
              });
            }
          );
        }
      },
    }
  );
};
